import * as tslib_1 from "tslib";
import { Subject } from 'rxjs/internal/Subject';
import { environment } from '../../../../environments/environment';
export class ComponentBase {
    constructor() {
        this._unsubscribeAll = new Subject();
    }
    /**
    * On destroy
    */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    getSelectedItemLabel(data, value) {
        const item = data.find(i => i.value === value);
        if (!item) {
            return '';
        }
        return item.label;
    }
    onCopy() {
        this._notifierService.showSuccess('Đã copy !');
    }
    urlImageAvatar(path) {
        if (path == null || path == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/Avatar${path}`;
    }
    urlImageMedium(path) {
        if (path == null || path == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/Medium${path}`;
    }
    urlImageLarge(path) {
        if (path == null || path == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/Large${path}`;
    }
    urlImageOriginal(path) {
        if (path == null || path == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/Original${path}`;
    }
    getImageAvatar(id) {
        return id ? `${environment.apiDomain.fileEndpoint}/files/image/Avatar/${id}` : null;
    }
    getImageMedium(id) {
        return id ? `${environment.apiDomain.fileEndpoint}/files/image/Medium/${id}` : null;
    }
    getImageLarge(id) {
        return id ? `${environment.apiDomain.fileEndpoint}/files/image/Large/${id}` : null;
    }
    getImageOriginal(id) {
        return id ? `${environment.apiDomain.fileEndpoint}/files/image/Original/${id}` : null;
    }
    GetStatus(item) {
        if (item.idStatus === 1000) {
            return `<span class="order don-moi">${item.status}</span>`;
        }
        if (item.idStatus === 31 || item.idStatus === 32) {
            return `<span class="order don-thanhcong">${item.status}</span>`;
        }
        if (item.idStatus === 22 || item.idStatus === 33) {
            return `<span class="order don-nguyhiem">${item.status}</span>`;
        }
        if (item.idStatus === 4 || item.idStatus === 40 || item.idStatus === 41 || item.idStatus === 999) {
            return `<span class="order don-thatbai">${item.status}</span>`;
        }
        else {
            return `<span class="order don-danggiao">${item.status}</span>`;
        }
    }
    GetActions(item) {
        if (!item.idAction) {
            return ``;
        }
        if (item.idAction === 1 || item.idAction === 13) {
            return `<span class="order don-moi">${item.actions}</span>`;
        }
        if (item.idAction === 2 || item.idStatus === 7 || item.idStatus === 3) {
            return `<span class="order don-thanhcong">${item.actions}</span>`;
        }
        if (item.idAction === 4 || item.idStatus === 8) {
            return `<span class="order don-nguyhiem">${item.actions}</span>`;
        }
        if (item.idAction === 5 || item.idStatus === 9 || item.idAction === 14 || item.idAction === 10) {
            return `<span class="order don-thatbai">${item.actions}</span>`;
        }
        else {
            return `<span class="order don-danggiao">${item.actions}</span>`;
        }
    }
    downloadFile(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var url = `${environment.apiDomain.fileEndpoint}/${item.path}`;
            //if (item.extention == ".jpeg" || item.extention == ".png" || item.extention == ".jpg" || item.extention == ".bmp" || item.extention == ".gif" || item.extention == ".bmp" || item.extention == ".apng" || item.extention == ".ico" || item.extention == ".webp" || item.extention == ".avif")
            if (item.type == 1)
                url = `${environment.apiDomain.fileEndpoint}/Original/${item.path}`;
            fetch(url)
                .then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const blob = yield response.blob();
                const urlDownload = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = urlDownload;
                link.setAttribute("download", item.fileName);
                link.click();
            }));
            return false;
        });
    }
    GetDate() {
        // Lấy ngày hôm nay và ngày hôm qua
        let today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        // Lấy ngày đầu tuần và cuối tuần
        let firstDayOfWeek = new Date(today);
        firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1);
        let lastDayOfWeek = new Date(today);
        lastDayOfWeek.setDate(today.getDate() + (7 - today.getDay()));
        // Lấy ngày đầu tháng và cuối tháng
        let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        // Lấy ngày đầu và cuối của tuần trước
        let firstDaysWeekAgo = new Date(firstDayOfWeek);
        firstDaysWeekAgo.setDate(firstDayOfWeek.getDate() - 7);
        let lastDaysWeekAgo = new Date(lastDayOfWeek);
        lastDaysWeekAgo.setDate(lastDaysWeekAgo.getDate() - 7);
        // Lấy ngày đầu và cuối của tháng trước
        let firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        let lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Ngày cuối của tháng trước
        // Lấy ngày đầu và cuối của 7 ngày trước
        let fSevenDayAgo = new Date(today);
        fSevenDayAgo.setDate(today.getDate() - 7);
        let lSevenDayAgo = new Date(today);
        // Lấy ngày đầu và cuối của 14 ngày trước
        let ftDayAgo = new Date(fSevenDayAgo);
        ftDayAgo.setDate(fSevenDayAgo.getDate() - 7);
        let ltDayAgo = new Date(today);
        // Lấy ngày đầu và cuối của 30 ngày trước
        let fttDayAgo = new Date(today);
        fttDayAgo.setDate(fttDayAgo.getDate() - 30);
        let lttDayAgo = new Date(today);
        return [
            { label: 'Chỉ tiêu', formDate: null, toDate: null },
            { label: 'Hôm nay', fromDate: today, toDate: today },
            // {label: 'Tuần này', fromDate: firstDayOfWeek, toDate: lastDayOfWeek},
            { label: 'Tháng này', fromDate: firstDayOfMonth, toDate: lastDayOfMonth },
        ];
    }
}
