/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-address-delivery.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "primeng/components/tooltip/tooltip";
import * as i4 from "./user-address-delivery.component";
import * as i5 from "../../services/configuration.service";
import * as i6 from "../../../dapfood/services/useraddress.service";
import * as i7 from "primeng/components/dynamicdialog/dynamicdialog-config";
import * as i8 from "primeng/components/dynamicdialog/dynamicdialog-ref";
import * as i9 from "primeng/components/dynamicdialog/dialogservice";
var styles_UserAddressDeliveryComponent = [i0.styles];
var RenderType_UserAddressDeliveryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserAddressDeliveryComponent, data: {} });
export { RenderType_UserAddressDeliveryComponent as RenderType_UserAddressDeliveryComponent };
function View_UserAddressDeliveryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "is-default"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" M\u1EB7c \u0111\u1ECBnh "]))], null, null); }
function View_UserAddressDeliveryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "item mt-20 mb-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "address-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "name-phoneNo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "code"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "phone"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "location-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserAddressDeliveryComponent_3)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 13, "div", [["class", "action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "a", [["class", "update_address_btn"], ["pTooltip", "C\u1EADp nh\u1EADt \u0111\u1ECBa ch\u1EC9"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowEdit(_v.context.$implicit.idUserAddress) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 4341760, null, 0, i3.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), (_l()(), i1.ɵted(-1, null, ["C\u1EADp nh\u1EADt"])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "a", [["class", "remove_address_btn"], ["pTooltip", "X\u00F3a \u0111\u1ECBa ch\u1EC9"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete(_v.context.$implicit.idUserAddress) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 4341760, null, 0, i3.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), (_l()(), i1.ɵted(-1, null, ["Xo\u00E1"])), (_l()(), i1.ɵeld(24, 0, null, null, 5, "div", [["class", "set-default"]], null, null, null, null, null)), i1.ɵdid(25, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(26, { "isDefault": 0 }), (_l()(), i1.ɵeld(27, 0, null, null, 2, "a", [["class", "default_address_btn"], ["pTooltip", "\u0111\u1EB7t l\u00E0m \u0111\u1ECBa ch\u1EC9 m\u1EB7c \u0111\u1ECBnh"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeDefaultAddress(_v.context.$implicit.idUserAddress) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 4341760, null, 0, i3.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), (_l()(), i1.ɵted(-1, null, ["Thi\u1EBFt l\u1EADp m\u1EB7c \u0111\u1ECBnh"]))], function (_ck, _v) { var currVal_4 = _v.context.$implicit.isDefault; _ck(_v, 15, 0, currVal_4); var currVal_5 = "C\u1EADp nh\u1EADt \u0111\u1ECBa ch\u1EC9"; _ck(_v, 19, 0, currVal_5); var currVal_6 = "X\u00F3a \u0111\u1ECBa ch\u1EC9"; _ck(_v, 22, 0, currVal_6); var currVal_7 = "set-default"; var currVal_8 = _ck(_v, 26, 0, _v.context.$implicit.isDefault); _ck(_v, 25, 0, currVal_7, currVal_8); var currVal_9 = "\u0111\u1EB7t l\u00E0m \u0111\u1ECBa ch\u1EC9 m\u1EB7c \u0111\u1ECBnh"; _ck(_v, 28, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.code; _ck(_v, 5, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.phone; _ck(_v, 11, 0, currVal_2); var currVal_3 = _v.context.$implicit.fullAddress; _ck(_v, 13, 0, currVal_3); }); }
function View_UserAddressDeliveryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserAddressDeliveryComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; _ck(_v, 2, 0, currVal_0); }, null); }
function View_UserAddressDeliveryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "no-address mt-20"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Nh\u00E0 thu\u1ED1c ch\u01B0a c\u00F3 \u0111\u1ECBa ch\u1EC9 giao h\u00E0ng n\u00E0o. Vui l\u00F2ng c\u1EADp nh\u1EADt th\u00F4ng tin \u0111\u1ECBa ch\u1EC9 giao h\u00E0ng!!! "]))], null, null); }
export function View_UserAddressDeliveryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { columnListContainerElement: 0 }), i1.ɵqud(402653184, 2, { bulkSelectContainerElement: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "container_delivery"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "header_delivery"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0110\u1ECBa ch\u1EC9 giao h\u00E0ng"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "vs-btn vs-top-table-btn vs-btn-action-reg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowEdit(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "__icon fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Th\u00EAm m\u1EDBi \u0111\u1ECBa ch\u1EC9"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "all-address"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserAddressDeliveryComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noAddress", 2]], null, 0, null, View_UserAddressDeliveryComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataSource.length > 0); var currVal_1 = i1.ɵnov(_v, 13); _ck(_v, 12, 0, currVal_0, currVal_1); }, null); }
export function View_UserAddressDeliveryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-address-delivery", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event, $event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_UserAddressDeliveryComponent_0, RenderType_UserAddressDeliveryComponent)), i1.ɵdid(1, 245760, null, 0, i4.UserAddressDeliveryComponent, [i1.Injector, i5.ConfigurationService, i6.UserAddressService, i7.DynamicDialogConfig, i8.DynamicDialogRef, i9.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserAddressDeliveryComponentNgFactory = i1.ɵccf("app-user-address-delivery", i4.UserAddressDeliveryComponent, View_UserAddressDeliveryComponent_Host_0, {}, {}, []);
export { UserAddressDeliveryComponentNgFactory as UserAddressDeliveryComponentNgFactory };
