import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from '../../classes/base/second-page-index-base';
import { UserLogService } from '../../services/user-log.service';
export class LogUserChangesComponent extends SecondPageIndexBase {
    constructor(_injector, _userLogs) {
        super(null, _injector);
        this._injector = _injector;
        this._userLogs = _userLogs;
    }
    ngOnInit() {
        this.cols = [
            {
                field: 'invoiceTaxCode',
                header: 'Mã số thuế',
                visible: true,
                align: 'center',
                width: '12%',
            },
            {
                field: 'code',
                header: 'Mã khách hàng',
                visible: true,
                align: 'center',
                width: '10%',
            },
            {
                field: 'name',
                header: 'Tên KH',
                visible: true,
                align: 'center',
                width: '10%',
            },
            {
                field: 'phone',
                header: 'SĐT',
                visible: true,
                align: 'center',
                width: '10%',
            },
            {
                field: 'fullAddress',
                header: 'Địa chỉ',
                visible: true,
                align: 'center',
                width: '30%',
            },
            {
                field: 'lng',
                header: 'Kinh độ',
                visible: true,
                align: 'center',
                width: '10%',
            },
            {
                field: 'lat',
                header: 'Vĩ độ',
                visible: true,
                align: 'center',
                width: '10%',
            }
        ];
        this.getUserLog();
    }
    getUserLog() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.userId) {
                yield this._userLogs.LogChangeInfomation(this.userId)
                    .then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (response.status) {
                        const data = response.data.reverse();
                        this.dataSource = this.markChanges(data);
                        this.total = response.totalRecord;
                    }
                }), () => {
                    this._notifierService.showHttpUnknowError();
                });
            }
        });
    }
    markChanges(data) {
        for (let i = 1; i < data.length; i++) {
            const currentRecord = data[i];
            const previousRecord = data[i - 1];
            currentRecord.changes = {};
            for (const key in currentRecord) {
                if (currentRecord.hasOwnProperty(key) && key !== 'changes') {
                    if (currentRecord[key] !== previousRecord[key]) {
                        currentRecord.changes[key] = true;
                    }
                }
            }
        }
        console.log(data);
        return data;
    }
}
