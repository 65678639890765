import { Component, Injector, OnInit } from '@angular/core';
import { ProvincesService } from '../../../dapfood/services/provinces.service';
import { DistrictsService } from '../../../dapfood/services/districts.service';
import { WardsService } from '../../../dapfood/services/wards.service';
import { ConfigurationService } from '../../services/configuration.service';
import { SecondPageEditBase } from '../../classes/base/second-page-edit-base';
import { UserAddressService } from '../../../dapfood/services/useraddress.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
import { SecondPageIndexBase } from '../../classes/base/second-page-index-base';
import { UserAddressDeliveryEditComponent } from './user-address-delivery-edit/user-address-delivery-edit.component';

@Component({
  selector: 'app-user-address-delivery',
  templateUrl: './user-address-delivery.component.html',
  styleUrls: ['./user-address-delivery.component.scss']
})
export class UserAddressDeliveryComponent extends SecondPageIndexBase implements OnInit {
  isLoading = false;
  searchModel: any = {
    key: '',
    fromDateCustomer: '',
    toDateCustomer: '',
    status: '',
    idProvince: -1,
  };
  cols2 = [];
  cols = [];
  userId: number = 0;
  userName: string;
  modelEdit: any = {};
  isView = false;
  listCustomer: any;
  pageCustomer = 1;
  limitCustomer = 100;
  index = 0;
  listItemNumberPerPage = [
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
    { label: '500', value: 500 },
    { label: '1000', value: 1000 },
  ];
  vi: any;
  list_statusType = [];
  totalCustomer = 0;
  dataTotalCustomer = [];
  status_options: any = [];
  UserIdSelected: any;
  constructor(
    protected _injector: Injector,
    private _configurationService: ConfigurationService,
    private _userAddress: UserAddressService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
  ) {
    super(null, _injector);
  }
 

  async ngOnInit() {
    this.vi = this._configurationService.calendarVietnamese;
    // this.cols = [
    //   {
    //     field: 'code',
    //     header: 'Mã',
    //     visible: true,
    //     align: 'left',
    //   },
    //   {
    //     field: 'phone',
    //     header: 'Điện thoại',
    //     visible: true,
    //     align: 'left',
    //   },
    //   {
    //     field: 'name',
    //     header: 'Tên khách hàng',
    //     visible: true,
    //     align: 'left',
    //   },
    //   {
    //     field: 'pharmacyTypeName',
    //     header: 'Loại hình',
    //     visible: true,
    //     align: 'left',
    //   },
    //   {
    //     field: 'createdDate',
    //     header: 'Ngày tham gia',
    //     visible: true,
    //     align: 'center',
    //     width: '8%',
    //   },
    //   {
    //     field: 'countOrdersFinish',
    //     header: 'Số đơn (TC)',
    //     visible: true,
    //     align: 'center',
    //     width: '10%',
    //     sort: true,
    //   },
    //   {
    //     field: 'totalOrdersFinish',
    //     header: 'Tổng tiền (TC)',
    //     width: '10%',
    //     visible: true,
    //     align: 'right',
    //     sort: true,
    //   },
    //   {
    //     field: 'nameRef',
    //     header: 'Người phụ trách',
    //     width: '10%',
    //     visible: true,
    //     align: 'center',
    //     sort: true,
    //   },
    // ];

    this.userId = this.config.data.userId;
    this.userName = this.config.data.userName;

    await this.getListDeliveryAddress(this.userId);
  }

  async getListDeliveryAddress(userId: number) {
    await this._userAddress.GetsAddressByUserId('',userId,0,20,'',false).then(
     async re => {
        if(re.status) {
          this.dataSource = re.data;
        }
      }
    );
  }

  onEdit(type: number, check: number) {

  }

  callOmiCall(item) {
    
  }

  async changeDefaultAddress(id: number) {
    const currentAddress = this.dataSource.find(address => address.idUserAddress === id);

    if (currentAddress && currentAddress.isDefault) {
        return;
    }

    await this._userAddress.SetDefaultAddress(id, this.userId).then(
        async re => {
          if (re.status) {
            this.dataSource.forEach(x => {
              x.isDefault = false;
            });
            var reAddress = this.dataSource.find(address => address.idUserAddress === re.data.id)
            reAddress.isDefault = true;
          }
        }
    );
}

onDelete(id: number) {
  this._notifierService.showConfirm('Xóa bản ghi?', 'Bạn có chắc muốn xóa bản ghi này?').then(rs => {
      this._userAddress.AdminDeleteAddress(id).then(re => {
          if (re.status) {
              this._notifierService.showDeleteDataSuccess();
              this.dataSource = this.dataSource.filter(obj => obj.idUserAddress !== id);
          }
      }).catch(error => {
          this.isLoading = false;
          this._notifierService.showError(error.message);
      });
  }).catch(err => {
      this._notifierService.showDeleteDataError();
  });
}

onShowEdit(id) {
  let addrCurr = this.dataSource.filter(x => x.idUserAddress == id);
  let fullAddress = addrCurr.length > 0 ? addrCurr[0].fullAddress : '';
  let headerTitle = addrCurr.length > 0 
    ? `Sửa thông tin địa chỉ giao hàng: ${fullAddress}` 
    : 'Tạo địa chỉ giao hàng mới';

  this.ref = this.dialogService.open(UserAddressDeliveryEditComponent, {
      data: {
          idAddress: id,
          fullAddress: fullAddress,
          userId: this.userId
      },
      header: headerTitle,
      width: '45%',
      height: 'calc(100vh - 500px)',
      styleClass: "vs-modal",
      contentStyle: { 'overflow': 'auto' },
      baseZIndex: 1001,
      closeOnEscape: true,
  });
  this.ref.onClose.subscribe((re: any) => {
        if (re == null || re === undefined) {
          return;
        }
        this.isLoading = false;
        var checkId = this.dataSource.find(address => address.idUserAddress === re);
        if (checkId) {
            this._userAddress.GetDetailAddress(re).then(rs => {
              if (rs.status) {
                this.modelEdit = rs.data[0];
              }
              const index = this.dataSource.findIndex(address => address.idUserAddress === re);
              if (index !== -1) {
                  this.dataSource[index] = this.modelEdit;
              }
            }); 
        }
        else {
          this._userAddress.GetDetailAddress(re).then(rs => {
            if (rs.status) {
              this.modelEdit = rs.data[0];
            }
            this.dataSource.push(this.modelEdit);
          });
        }
});
}


}
