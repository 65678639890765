import * as tslib_1 from "tslib";
import { OnInit, Injector } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ConfirmationService, DialogService, DynamicDialogRef } from 'primeng/primeng';
import { environment } from '../../../../../environments/environment';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { VsFileUploadService } from '../services/file-upload.service';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { VsFileViewerComponent } from '../vs-file-viewer/vs-file-viewer.component';
export class HqFileViewCommentComponent extends SecondPageEditBase {
    constructor(_injector, confirmationService, _fileUploadService, dialogService) {
        super(null, _injector);
        this._injector = _injector;
        this.confirmationService = confirmationService;
        this._fileUploadService = _fileUploadService;
        this.dialogService = dialogService;
        this.disabled = false;
        this.progressValue = 0;
        this.noDelete = false;
        this.chooseLabel = 'Chọn file';
        this.multiple = false;
        this.styleButton = false;
        this.defaultImage = false;
        this.showIconOnly = false;
        this.icon = 'fa fa-upload';
        this.viewOnly = false;
        this.fileType = ''; // '' , image/* , video/* , 
        this.onChangeControl = (obj) => { };
        this.onTouched = () => { };
    }
    ngOnInit() {
    }
    writeValue(obj) {
        this.listUploaded = [];
        if (obj) {
            this.listUploaded = obj.split(",");
            this._fileUploadService.GetsByIds(obj).then(rs => {
                if (rs.status) {
                    this.listUploaded = rs.data;
                }
            }).catch(err => {
                console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
            });
        }
    }
    registerOnChange(fn) {
        this.onChangeControl = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    deleteFile(id) {
        this.confirmationService.confirm({
            message: 'Bạn có chắc chắn muốn xóa file này không ?',
            accept: () => {
                const fileToRemove = this.listUploaded.findIndex(x => x.id === id);
                this.listUploaded.splice(fileToRemove, 1);
                let files = this.listUploaded.map(x => x.id).join(",");
                this.onChangeControl(files);
                this.writeValue(files);
            }
        });
    }
    viewFile(item) {
        // Nếu file là hình ảnh hoặc vido thì dùng FileViewer, Nếu là tài liệu thì dùng VsFileViewer
        if (item.type == 1 || item.type == 3)
            this._FileViewerComponent.showPopupObject(item, this.listUploaded);
        else {
            this.ref = this.dialogService.open(VsFileViewerComponent, {
                data: item,
                showHeader: true,
                header: item.fileName,
                width: '100%',
                height: 'calc(100vh - 0px)',
                styleClass: "vs-modal vs-file-viewer",
                contentStyle: { 'overflow': 'auto' },
                baseZIndex: 1010,
                closeOnEscape: true
            });
        }
    }
    downloadFile(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var url = `${environment.apiDomain.fileEndpoint}/${item.path}`;
            //if (item.extention == ".jpeg" || item.extention == ".png" || item.extention == ".jpg" || item.extention == ".bmp" || item.extention == ".gif" || item.extention == ".bmp" || item.extention == ".apng" || item.extention == ".ico" || item.extention == ".webp" || item.extention == ".avif")
            if (item.type == 1)
                url = `${environment.apiDomain.fileEndpoint}/Original/${item.path}`;
            fetch(url)
                .then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const blob = yield response.blob();
                const urlDownload = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = urlDownload;
                link.setAttribute("download", item.fileName);
                link.click();
            }));
            return false;
        });
    }
    downloadFileAll() {
        this.listUploaded.forEach(element => {
            this.downloadFile(element);
        });
    }
}
