import { Directive, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[appPasteClipboard]'
})
export class PasteClipboardDirective {
  @Output() appPasteClipboard: EventEmitter<any> = new EventEmitter();
  constructor() { }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    let items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let item of items) {
      if (item.type.indexOf('image') === 0) {
        let blob = item.getAsFile();
        let date = new Date();
        let timestamp = date.getDate() + '-' + date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();
        let filename = "image_" + timestamp + ".png";
        let newFile = new File([blob], filename, { type: "image/png" });
        this.appPasteClipboard.emit(newFile);
      }
    }
  }

}
