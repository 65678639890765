import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { ProvincesService } from '../../../../dapfood/services/provinces.service';
import { DistrictsService } from '../../../../dapfood/services/districts.service';
import { WardsService } from '../../../../dapfood/services/wards.service';
import { UserAddressService } from '../../../../dapfood/services/useraddress.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Utilities } from '../../../../shared/utilities';

@Component({
  selector: 'app-user-address-delivery-edit',
  templateUrl: './user-address-delivery-edit.component.html',
  styleUrls: ['./user-address-delivery-edit.component.scss']
})
export class UserAddressDeliveryEditComponent extends SecondPageEditBase implements OnInit {
  isLoading = false;
  searchModel: any = {
    key: '',
    fromDateCustomer: '',
    toDateCustomer: '',
    status: '',
    idProvince: -1,
  };
  submitted = false;
  cols2 = [];
  cols = [];
  province_options: any = [];
  district_options: any = [];
  ward_options: any = [];
  idAddress: number = 0;
  userId: number = 0;
  fullAddress: string;
  modelEdit: any = {};
  isView = false;
  listCustomer: any;
  pageCustomer = 1;
  limitCustomer = 100;
  index = 0;
  listItemNumberPerPage = [
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
    { label: '500', value: 500 },
    { label: '1000', value: 1000 },
  ];
  vi: any;
  list_statusType = [];
  totalCustomer = 0;
  dataTotalCustomer = [];
  status_options: any = [];
  UserIdSelected: any;
  constructor(
    protected _injector: Injector,
    private _ProvincesService: ProvincesService,
    private _DistrictsService: DistrictsService,
    private _WardsService: WardsService,
    private _configurationService: ConfigurationService,
    private _userAddress: UserAddressService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
  ) {
    super(null, _injector);
  }
  async ngOnInit() {
    this.vi = this._configurationService.calendarVietnamese;
    this.idAddress = this.config.data.idAddress;
    this.fullAddress = this.config.data.fullAddress;
    this.userId = this.config.data.userId;
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])), 
      idProvince: new FormControl(''),
      idDistrict: new FormControl(''),
      idWard: new FormControl(''),
      address: new FormControl(''),
      code: new FormControl(''),
    });

    await this.GetDetailAddress(this.idAddress);
    await this.onLoadDistricts();
    await this.onLoadProvinces();
    await this.onLoadWards();
  }

  async GetDetailAddress(id: number) {
    if(id > 0) {
      await this._userAddress.GetDetailAddress(id).then(rs => {
        if (rs.status) {
          this.modelEdit = rs.data[0];
        }
      });
    }
  }

  async onLoadDistricts() {
    await this._DistrictsService.GetShort(this.modelEdit.idProvince).then(rs => {
      if (rs.status) {
        this.district_options = rs.data;
      }
    });
  }

  async onLoadProvinces() {
    await this._ProvincesService.GetShort().then(rs => {
      if (rs.status) {
        this.province_options = rs.data;
      }
    });
  }

  async onLoadWards() {
    await this._WardsService.GetShort(this.modelEdit.idDistrict).then(rs => {
      if (rs.status) {
        this.ward_options = rs.data;
      }
    });
  }

  save() {
    this.isLoading = true;
    this.submitted = true;

    if (this.formGroup.valid) {
      this.modelEdit.phone = this.modelEdit.phone; // this.f.userName.setValue(this.f.phone.value);
      this.modelEdit.name = this.modelEdit.name; // this.f.userName.setValue(this.f.name.value);
      this.modelEdit.userId = this.userId;
      this.modelEdit.id = this.idAddress;
      this._userAddress.AdminSave(this.modelEdit).then(async (response) => {
        if (response.status) {
          if (response.data != null && response.data.userId > 0) {
            this.modelEdit.userId = response.data.userId;
            this.modelEdit.isDeleted = 0;
            this.modelEdit.id = response.data.id;
            this._notifierService.showSuccess(response.message);
            this.ref.close(this.modelEdit.id);
          }
        } else {
          this.isLoading = false;
          this._notifierService.showWarning('Cập nhật địa chỉ giao hàng không thành công!!!.\n' + response.message);
        }
      })
        .catch(error => {
          this.isLoading = false;
          this._notifierService.showWarning(Utilities.getErrorDescription(error));
        });
    }

    this.isLoading = false;
  }

}
