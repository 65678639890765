import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Utilities } from '../shared/utilities';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { UserService } from '../lib-shared/services/user.service';
import { LogSmsService } from '../dapfood/services/logsms.service';
export class ProfileCmtComponent extends SecondPageEditBase {
    constructor(_injector, formBuilder, _userRoleService, _LogSmsService) {
        super(null, _injector);
        this._injector = _injector;
        this.formBuilder = formBuilder;
        this._userRoleService = _userRoleService;
        this._LogSmsService = _LogSmsService;
        this.USER_INFO_KEY = 'user_info';
        this.modelEdit = {};
        this.isLoading = false;
        this.isSaving = false;
        this.isDeleting = false;
        this.isView = false;
        this.userTypeDataSource = [];
        this.captchaUrl = '';
        this.captchaInvalid = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.formGroup = new FormGroup({
                code: new FormControl(''),
                cmt: new FormControl('', Validators.compose([Validators.required])),
                cmtBackside: new FormControl('', Validators.compose([Validators.required])),
                cmtFront: new FormControl('', Validators.compose([Validators.required]))
            });
        });
    }
    save() {
        this.isSaving = true;
        if (this.modelEdit.userId > 0) {
            this._userRoleService.UpdateCmt(this.modelEdit)
                .then(response => {
                if (response.status) {
                    this._notifierService.showSuccess('Cập nhật CMND thành công!');
                    this.isShow = false;
                    this.closePopup.emit();
                }
                else {
                    this.isSaving = false;
                    this._notifierService.showWarning('Cập nhật thông tin CMND thất bại.\nNội dung lỗi: ' + response.message);
                }
            })
                .catch(error => {
                this.isSaving = false;
                this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
            });
        }
    }
    showPopup(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isShow = true;
            if (id) {
                this.isLoading = true;
                this.item = id;
                this.isView = true;
                this._userRoleService
                    .getCurrent()
                    .then(response => {
                    this.isLoading = false;
                    if (response.status) {
                        this.modelEdit = response.data;
                    }
                    else {
                        if (response.message === 'ERR_USER_USERNAME_NOT_ALLOWED') {
                            this._notifierService.showWarning('Tên tài khoản không hợp lệ (Tối thiểu 4 ký tự, không chứa ký tự đặc biệt trừ _ và .)');
                        }
                        else {
                            this._notifierService.showWarning('Xảy ra lỗi không xác định.');
                        }
                    }
                })
                    .catch(error => {
                    this.isLoading = false;
                    this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
                });
            }
            else {
                this.captchaUrl = this._userRoleService.getCaptchaUrl();
                this.togglePopupDelete();
            }
        });
    }
    togglePopupDelete() {
        this.modelEdit = {};
    }
    GetOTP() {
        this._LogSmsService.GetOtp(4).then(rs => {
            if (rs.status) {
                this._notifierService.showSuccess(rs.message);
            }
            else {
                this._notifierService.showError(rs.message);
            }
        });
    }
}
