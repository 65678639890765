import { ActivatedRoute, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class CustomRouterService {
    constructor(_activatedRoute, _router) {
        this._activatedRoute = _activatedRoute;
        this._router = _router;
    }
    openChildPage(childPageRouter, navExtra = {}) {
        this._router.navigate([childPageRouter], navExtra);
    }
    openPage(pageRouter, navExtra = {}) {
        this._router.navigate([pageRouter], navExtra);
    }
    backToParentPage() {
        this._router.navigate(['..'], { relativeTo: this._activatedRoute });
    }
    openCreatePage(navExtra = {}) {
        this._router.navigate(['./create'], navExtra);
    }
    openEditPage(id, navExtra = {}) {
        this._router.navigate([`./edit/${id}`], navExtra);
    }
    parseFragment(fragmentString) {
        if (!fragmentString || fragmentString === '') {
            return {};
        }
        const urlSearchParam = fragmentString.split('&');
        const obj = {};
        for (const index in urlSearchParam) {
            const item = urlSearchParam[index].split('=');
            obj[item[0]] = item[1];
        }
        return obj;
    }
    getUrlWithoutParamAndFragment() {
    }
}
CustomRouterService.ngInjectableDef = i0.defineInjectable({ factory: function CustomRouterService_Factory() { return new CustomRouterService(i0.inject(i1.ActivatedRoute), i0.inject(i1.Router)); }, token: CustomRouterService, providedIn: "root" });
