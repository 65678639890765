import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { UserAddressService } from '../../../dapfood/services/useraddress.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
import { SecondPageIndexBase } from '../../classes/base/second-page-index-base';
import { UserAddressDeliveryEditComponent } from './user-address-delivery-edit/user-address-delivery-edit.component';
export class UserAddressDeliveryComponent extends SecondPageIndexBase {
    constructor(_injector, _configurationService, _userAddress, config, ref, dialogService) {
        super(null, _injector);
        this._injector = _injector;
        this._configurationService = _configurationService;
        this._userAddress = _userAddress;
        this.config = config;
        this.ref = ref;
        this.dialogService = dialogService;
        this.isLoading = false;
        this.searchModel = {
            key: '',
            fromDateCustomer: '',
            toDateCustomer: '',
            status: '',
            idProvince: -1,
        };
        this.cols2 = [];
        this.cols = [];
        this.userId = 0;
        this.modelEdit = {};
        this.isView = false;
        this.pageCustomer = 1;
        this.limitCustomer = 100;
        this.index = 0;
        this.listItemNumberPerPage = [
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
            { label: '200', value: 200 },
            { label: '500', value: 500 },
            { label: '1000', value: 1000 },
        ];
        this.list_statusType = [];
        this.totalCustomer = 0;
        this.dataTotalCustomer = [];
        this.status_options = [];
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.vi = this._configurationService.calendarVietnamese;
            // this.cols = [
            //   {
            //     field: 'code',
            //     header: 'Mã',
            //     visible: true,
            //     align: 'left',
            //   },
            //   {
            //     field: 'phone',
            //     header: 'Điện thoại',
            //     visible: true,
            //     align: 'left',
            //   },
            //   {
            //     field: 'name',
            //     header: 'Tên khách hàng',
            //     visible: true,
            //     align: 'left',
            //   },
            //   {
            //     field: 'pharmacyTypeName',
            //     header: 'Loại hình',
            //     visible: true,
            //     align: 'left',
            //   },
            //   {
            //     field: 'createdDate',
            //     header: 'Ngày tham gia',
            //     visible: true,
            //     align: 'center',
            //     width: '8%',
            //   },
            //   {
            //     field: 'countOrdersFinish',
            //     header: 'Số đơn (TC)',
            //     visible: true,
            //     align: 'center',
            //     width: '10%',
            //     sort: true,
            //   },
            //   {
            //     field: 'totalOrdersFinish',
            //     header: 'Tổng tiền (TC)',
            //     width: '10%',
            //     visible: true,
            //     align: 'right',
            //     sort: true,
            //   },
            //   {
            //     field: 'nameRef',
            //     header: 'Người phụ trách',
            //     width: '10%',
            //     visible: true,
            //     align: 'center',
            //     sort: true,
            //   },
            // ];
            this.userId = this.config.data.userId;
            this.userName = this.config.data.userName;
            yield this.getListDeliveryAddress(this.userId);
        });
    }
    getListDeliveryAddress(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._userAddress.GetsAddressByUserId('', userId, 0, 20, '', false).then((re) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (re.status) {
                    this.dataSource = re.data;
                }
            }));
        });
    }
    onEdit(type, check) {
    }
    callOmiCall(item) {
    }
    changeDefaultAddress(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const currentAddress = this.dataSource.find(address => address.idUserAddress === id);
            if (currentAddress && currentAddress.isDefault) {
                return;
            }
            yield this._userAddress.SetDefaultAddress(id, this.userId).then((re) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (re.status) {
                    this.dataSource.forEach(x => {
                        x.isDefault = false;
                    });
                    var reAddress = this.dataSource.find(address => address.idUserAddress === re.data.id);
                    reAddress.isDefault = true;
                }
            }));
        });
    }
    onDelete(id) {
        this._notifierService.showConfirm('Xóa bản ghi?', 'Bạn có chắc muốn xóa bản ghi này?').then(rs => {
            this._userAddress.AdminDeleteAddress(id).then(re => {
                if (re.status) {
                    this._notifierService.showDeleteDataSuccess();
                    this.dataSource = this.dataSource.filter(obj => obj.idUserAddress !== id);
                }
            }).catch(error => {
                this.isLoading = false;
                this._notifierService.showError(error.message);
            });
        }).catch(err => {
            this._notifierService.showDeleteDataError();
        });
    }
    onShowEdit(id) {
        let addrCurr = this.dataSource.filter(x => x.idUserAddress == id);
        let fullAddress = addrCurr.length > 0 ? addrCurr[0].fullAddress : '';
        let headerTitle = addrCurr.length > 0
            ? `Sửa thông tin địa chỉ giao hàng: ${fullAddress}`
            : 'Tạo địa chỉ giao hàng mới';
        this.ref = this.dialogService.open(UserAddressDeliveryEditComponent, {
            data: {
                idAddress: id,
                fullAddress: fullAddress,
                userId: this.userId
            },
            header: headerTitle,
            width: '45%',
            height: 'calc(100vh - 500px)',
            styleClass: "vs-modal",
            contentStyle: { 'overflow': 'auto' },
            baseZIndex: 1001,
            closeOnEscape: true,
        });
        this.ref.onClose.subscribe((re) => {
            if (re == null || re === undefined) {
                return;
            }
            this.isLoading = false;
            var checkId = this.dataSource.find(address => address.idUserAddress === re);
            if (checkId) {
                this._userAddress.GetDetailAddress(re).then(rs => {
                    if (rs.status) {
                        this.modelEdit = rs.data[0];
                    }
                    const index = this.dataSource.findIndex(address => address.idUserAddress === re);
                    if (index !== -1) {
                        this.dataSource[index] = this.modelEdit;
                    }
                });
            }
            else {
                this._userAddress.GetDetailAddress(re).then(rs => {
                    if (rs.status) {
                        this.modelEdit = rs.data[0];
                    }
                    this.dataSource.push(this.modelEdit);
                });
            }
        });
    }
}
