import { InjectionToken } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
export function moduleConfigFunc() {
    return new InjectionToken('');
}
export class ModuleConfigService {
    constructor(moduleConfigVal = null) {
        // console.log('module config inject', moduleConfigVal());
        this._config = moduleConfigVal() ||
            {
                ApiFileUpload: `${environment.apiDomain.fileEndpoint}/upload`
            };
    }
    getConfig() {
        return this._config;
    }
}
ModuleConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ModuleConfigService_Factory() { return new ModuleConfigService(i0.inject(moduleConfigFunc, 8)); }, token: ModuleConfigService, providedIn: "root" });
