import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';

@Injectable()
export class UserLogService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/UsersTemp`);
    }

    LogChangeInfomation(userId: number) {
        const queryString = `${this.serviceUri}/LogChangeInfomation?userId=${userId}`;
        return this.defaultGet(queryString);
    }

}
