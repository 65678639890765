import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { SecondPageEditBase } from '../../classes/base/second-page-edit-base';
import { UserGlobalService } from '../../../services/user-global.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
export class UserViewComponent extends SecondPageEditBase {
    constructor(_injector, _userService, config, ref) {
        super(null, _injector);
        this._injector = _injector;
        this._userService = _userService;
        this.config = config;
        this.ref = ref;
        this.modelEdit = {};
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.showData)
                yield this.getDetailUser(this.userId);
        });
    }
    getDetailUser(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._userService.GetDetailInfoUser(id).then(rs => {
                if (rs.status) {
                    this.modelEdit = rs.data;
                    // this.modelEdit.avatar = this.getImageAvatar(this.modelEdit?.avatar);
                }
            });
        });
    }
    urlMess() {
        return `/chat/${this.userId}`;
    }
}
