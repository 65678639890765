<p-dialog [header]="'Đổi mật khẩu'" [style]="{'max-width': '550px'}" [contentStyle]="{'max-height': getMaxWidthDialog()}" class="normal-form vs-modal" [(visible)]="isShow" [modal]="true" [positionTop]="30" [maximizable]="true" [responsive]="true" [autoZIndex]="true">
    <div class="vs-modal-content-panel vs-form-container default-control-label-width">
        <form #formElement [formGroup]="formGroup" focusInvalidInput>
            <div class="ui-g row">
                <p-messages [(value)]="msgs"></p-messages>
            </div>
            <div class="ui-g">
                <div class="vs-form-control ui-g-12 ui-lg-12">
                    <div class="__form-control-label ui-g-12 ui-md-4 ui-lg-8" for="oldPassword">
                        Mật khẩu hiện tại: </div>
                    <div class="__form-control-inputs ui-g-12 ui-md-8 ui-lg-4">
                        <div class="__input-wrapper required">
                            <input formControlName="oldPassword" class="__input" pInputText type="password" [(ngModel)]="itemDetail.oldPassword" />
                        </div>
                    </div>
                </div>
                <div class="vs-form-control ui-g-12 ui-lg-12">
                    <div class="__form-control-label ui-g-12 ui-md-4 ui-lg-8" for="passwordHash">
                        Mật khẩu mới: </div>
                    <div class="__form-control-inputs ui-g-12 ui-md-8 ui-lg-4">
                        <div class="__input-wrapper required">
                            <input formControlName="passwordHash" class="__input" pInputText type="password" [(ngModel)]="itemDetail.passwordHash" />
                        </div>
                    </div>
                </div>
                <div class="vs-form-control ui-g-12 ui-lg-12">
                    <div class="__form-control-label ui-g-12 ui-md-4 ui-lg-8" for="confirmPassword">
                        Nhập lại mật khẩu: </div>
                    <div class="__form-control-inputs ui-g-12 ui-md-8 ui-lg-4">
                        <div class="__input-wrapper required">
                            <input formControlName="confirmPassword" class="__input" pInputText type="password" [(ngModel)]="itemDetail.confirmPassword" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>


    <p-footer>
        <div class="ui-g row">
            <div class="ui-g-12">
                <button class="vs-btn vs-modal-btn vs-btn-action-edit" type="button" (click)="save()" [disabled]="submitting">
                    <span class="__icon fa fa-floppy-o"></span>
                    <span class="__label">Lưu</span>
                </button>
                <button type="button" class="vs-btn vs-modal-btn vs-btn-action-back" (click)="cancel()" [disabled]="submitting">
                    <span class="__icon fa fa-times"></span>
                    <span class="__label">Đóng</span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>