<div class="user_info">
  <div class="avatar">
      <vs-avatar-view [(ngModel)]="modelEdit" [size]="80" [fontsize]="22" [mouseLeave]="true"></vs-avatar-view>
  </div>
  <div class="info">
    <div class="name">{{modelEdit.name}}</div>
    <div class="other_info">
      <div class="phone_info" *ngIf="modelEdit.phone">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.20711 14.7929C11.5251 17.111 13.6781 18.4033 15.2802 19.121C16.6787 19.7475 18.3296 19.2562 19.6167 17.9691L19.9114 17.6744L16.3752 15.4241C15.7026 16.4048 14.4319 16.979 13.1632 16.4434C12.2017 16.0376 10.9302 15.3445 9.7929 14.2071C8.65557 13.0698 7.96243 11.7983 7.55659 10.8369C7.02105 9.56817 7.59528 8.29741 8.57591 7.62479L6.32562 4.08863L6.0309 4.38335C4.74381 5.67044 4.25256 7.32131 4.87905 8.71986C5.59671 10.322 6.88908 12.4749 9.20711 14.7929ZM14.4626 20.9462C12.6479 20.1334 10.2905 18.7047 7.7929 16.2071C5.29532 13.7096 3.86668 11.3521 3.05381 9.53748C1.9781 7.13609 2.95955 4.62627 4.61669 2.96913L4.91141 2.67441C5.81615 1.76967 7.32602 1.93541 8.01294 3.01488L10.8437 7.46315C10.9957 7.70201 11.0393 7.99411 10.9637 8.26696C10.8881 8.53981 10.7005 8.76784 10.4472 8.89446L9.81354 9.2113C9.38171 9.42721 9.2931 9.80786 9.39916 10.0591C9.73804 10.8619 10.3046 11.8904 11.2071 12.7929C12.1097 13.6955 13.1381 14.262 13.9409 14.6009C14.1922 14.7069 14.5728 14.6183 14.7887 14.1865L15.1056 13.5528C15.2322 13.2996 15.4602 13.1119 15.7331 13.0363C16.0059 12.9607 16.298 13.0044 16.5369 13.1564L20.9852 15.9871C22.0646 16.674 22.2304 18.1839 21.3256 19.0886L21.0309 19.3833C19.3738 21.0405 16.8639 22.0219 14.4626 20.9462Z" fill="#000000" />
        </svg>
        <span>{{modelEdit.phone}}</span>
      </div>
      <div class="email_info" *ngIf="modelEdit.email">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 7.00005L10.2 11.65C11.2667 12.45 12.7333 12.45 13.8 11.65L20 7" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <rect x="3" y="5" width="18" height="14" rx="2" stroke="#000000" stroke-width="2" stroke-linecap="round" />
        </svg>
        <span>{{modelEdit.email}}</span>
      </div>
      <!-- <div class="address_info">
        <svg width="24" height="24" viewBox="0 0 8.4666669 8.4666669" id="svg8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg">
          <defs id="defs2" />
          <g id="layer1" transform="translate(0,-288.53332)">
            <path d="m 4.2324219,288.79688 c -1.6042437,0 -2.9101556,1.30591 -2.9101563,2.91015 -10e-7,2.82277 2.7460938,4.96875 2.7460938,4.96875 a 0.26460978,0.26460978 0 0 0 0.3300781,0 c 0,0 2.7460996,-2.14598 2.7460937,-4.96875 -3.4e-6,-1.60424 -1.3078657,-2.91015 -2.9121093,-2.91015 z m 0,0.52929 c 1.3182605,0 2.3828097,1.0626 2.3828125,2.38086 4.8e-6,2.30926 -2.0910618,4.13374 -2.3808594,4.38086 -0.2884142,-0.24588 -2.3828134,-2.0707 -2.3828125,-4.38086 5e-7,-1.31826 1.0625988,-2.38086 2.3808594,-2.38086 z" id="path929" style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;text-anchor:start;white-space:normal;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation-filters:linearRGB;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.52916664;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:stroke fill markers;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate" />
            <path d="m 4.2324219,290.38477 c -0.7274912,0 -1.3222633,0.59477 -1.3222657,1.32226 -4.5e-6,0.7275 0.5947697,1.32422 1.3222657,1.32422 0.727496,0 1.3242233,-0.59672 1.3242187,-1.32422 -2.3e-6,-0.72749 -0.5967275,-1.32226 -1.3242187,-1.32226 z m 0,0.52929 c 0.4415089,0 0.7949204,0.35146 0.7949219,0.79297 2.7e-6,0.44151 -0.35341,0.79492 -0.7949219,0.79492 -0.441512,0 -0.7929715,-0.35341 -0.7929688,-0.79492 1.4e-6,-0.44151 0.3514598,-0.79297 0.7929688,-0.79297 z" id="circle931" style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;text-anchor:start;white-space:normal;;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation-filters:linearRGB;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.52916664;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:stroke fill markers;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate" />
          </g>
        </svg>
        <span>{{modelEdit.address}}</span>
      </div> -->
    </div>
  </div>
</div>
<div class="user_btn">
  <a [routerLink]="urlMess()" class="message">
    <svg _ngcontent-blc-c1="" alt="" fill="currentColor" height="20" width="20" style="color: #fff;" viewBox="0 0 28 28">
      <path _ngcontent-blc-c1="" d=" M14 2.042c6.76 0 12 4.952 12 11.64S20.76 25.322 14 25.322a13.091 13.091 0 0 1-3.474-.461.956 .956 0 0 0-.641.047L7.5 25.959a.961.961 0 0 1-1.348-.849l-.065-2.134a.957.957 0 0 0-.322-.684A11.389 11.389 0 0 1 2 13.682C2 6.994 7.24 2.042 14 2.042ZM6.794 17.086a.57.57 0 0 0 .827.758l3.786-2.874a.722.722 0 0 1 .868 0l2.8 2.1a1.8 1.8 0 0 0 2.6-.481l3.525-5.592a.57.57 0 0 0-.827-.758l-3.786 2.874a.722.722 0 0 1-.868 0l-2.8-2.1a1.8 1.8 0 0 0-2.6.481Z"></path>
    </svg>
    <span>Tin nhắn</span>
  </a>
</div>