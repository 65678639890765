import * as tslib_1 from "tslib";
import * as signalR from '@aspnet/signalr';
import { ModuleConfigService } from './module-config.service';
import { VsAuthenService } from '../auth/authen.service';
import { TopicUsersService } from './topicusers.service';
export class SignalRService {
    constructor(_moduleConfigService, _TopicUsersService, _authenService) {
        this._moduleConfigService = _moduleConfigService;
        this._TopicUsersService = _TopicUsersService;
        this._authenService = _authenService;
        this.maxRetryTimes = 10;
        this.wait = ms => new Promise(r => setTimeout(r, ms));
        this._moduleConfig = this._moduleConfigService.getConfig();
    }
    start(hub, viewCode, callback) {
        // kết nối lần đầu
        this.createConnection(hub);
        this.startConnection().then(rs => {
            this.subscribeViewCode(viewCode, callback);
            this.autoReconnect(hub);
        });
        // // Cứ sau 5 phút thì refresh lại kết nối
        // setInterval(() => {
        //     this.createConnection(hub);
        //     this.startConnection().then(rs => {
        //         this.subscribeViewCode(viewCode, callback);
        //         //this.autoReconnect(hub);
        //     });
        // }, 5000)  // 5 phút reconnect lại để đảm bảo không bị mất kết nối
    }
    startList(hub, viewCode, callback) {
        this.createConnection(hub);
        this.startConnection().then(rs => {
            viewCode.forEach(element => {
                this.subscribeViewCode(element, callback);
            });
            this.autoReconnect(hub);
        });
    }
    stop() {
        this._hubConnection.stop();
    }
    subscribeViewCode(viewCode, callback) {
        if (this.viewCodeSubs !== undefined) {
            this.viewCodeSubs.push({ viewCode, callback });
        }
        else {
            this.viewCodeSubs = [{ viewCode, callback }];
        }
        this.subscribe(viewCode, callback);
    }
    createConnection(hub) {
        this._hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${this._moduleConfig.Services.NotificationEndpoint}/${hub}`, { accessTokenFactory: () => this._authenService.getAccessToken() })
            .build();
    }
    autoReconnect(topic) {
        this._hubConnection.onclose(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.startConnection();
        }));
    }
    startConnection(retryCount = 1) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // not sure about it :)))
            // https://github.com/aspnet/SignalR/issues/2389
            Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
            this._hubConnection
                .start().then(() => {
                resolve(true);
            })
                .catch((err) => {
                if (this.maxRetryTimes - retryCount > 0) {
                    return this.wait(retryCount * retryCount * 1000)
                        .then(this.startConnection.bind(this, ++retryCount))
                        .then(resolve)
                        .catch(reject);
                }
                return reject(err);
            });
        }));
    }
    subscribe(viewCode, callback) {
        this._hubConnection.invoke('Join', viewCode).catch(err => console.error(err.toString()));
        this._hubConnection.on(viewCode, (data) => {
            callback(data);
        });
    }
    //CUSTOM SEND
    createConnectionDapFoodAPI(hub) {
        this._hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Debug)
            .withUrl(`https://devnotification.dapfood.net/NotificationHub`, {
            // accessTokenFactory: () => this._authenService.getAccessToken(),
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        })
            .build();
    }
    SendDataWeb(hub, Method, data) {
        this.createConnectionDapFoodAPI(hub);
        this.startConnection().then(rs => {
            Method.forEach(element => {
                this.sendDataByViewCode(element, data);
            });
            this.autoReconnect(hub);
        });
    }
    sendDataByViewCode(Method, data) {
        this._hubConnection.invoke(Method, data).catch(err => console.error(err.toString()));
    }
}
