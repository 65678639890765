<div class="container_delivery">
  <div class="header_delivery">
    <span>Địa chỉ giao hàng</span>
    <button type="button" class="vs-btn vs-top-table-btn vs-btn-action-reg" (click)="onShowEdit(0)">
      <span class="__icon fa fa-plus"></span>
      <span class="__label">Thêm mới địa chỉ</span>
    </button>
  </div>
  <div class="all-address">
    <div *ngIf="dataSource.length > 0; else noAddress">
      <div *ngFor="let address of dataSource" class="item mt-20 mb-20">
        <div class="address-info">
          <div class="name-phoneNo">
            <span class="code"><label>{{address.code}}</label></span>
            <br>
            <span class="name">{{ address.name }}</span> | <span class="phone">{{ address.phone }}</span>
          </div>
          <div class="location-info">
            {{ address.fullAddress }}
          </div>
          <a class="is-default" *ngIf="address.isDefault">
            Mặc định
          </a>
        </div>
        <div class="action">
          <div class="detail">
            <a class="update_address_btn" pTooltip="Cập nhật địa chỉ" (click)="onShowEdit(address.idUserAddress)">Cập nhật</a>
            <a class="remove_address_btn" pTooltip="Xóa địa chỉ" (click)="onDelete(address.idUserAddress)">Xoá</a>
          </div>

          <div class="set-default" [ngClass]="{'isDefault': address.isDefault}">
            <a class="default_address_btn" pTooltip="đặt làm địa chỉ mặc định" (click)="changeDefaultAddress(address.idUserAddress)">Thiết lập mặc định</a>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noAddress>
      <div class="no-address mt-20">
        Nhà thuốc chưa có địa chỉ giao hàng nào. Vui lòng cập nhật thông tin địa chỉ giao hàng!!!
      </div>
    </ng-template>

  </div>
</div>