
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../lib-shared/services/base.service';
import { environment } from '../../environments/environment';

@Injectable()
export class UserGlobalService extends BaseService {

  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/Users`);
  }
  GetDetailInfoUser(Id: number) {
    const queryString = `${this.serviceUri}/GetDetailInfoUser?id=${Id}`;
    return this.defaultGet(queryString);
  }
}
