// Trạng thái phê duyệt
export enum EnumStatus {
    Pending = 0,
    Active = 1,
    Cancel = 2,
    Reject = 3
}

// Loại giao dịch
export enum DealType {
    Thuong = 1,
    HoanLai = 2,
    NapDiem = 3,
    RutDiem = 4,
    HoanDiemRut = 5,
    CongDiemDonHangDN = 6,
    TruDiemLenDonTrucTiep = 7,
    DongBang = 8,
}

// Trạng thái giao dịch 
export enum StatementStatus {
    ChuaXuLy = 0,
    ThanhCong = 1,
    DangXuLy = 2,
    Huy = 3,
    Loi = 4,
}

//Trạng thái đăng ký
export enum ProductRegStatus {
    ChoDuyet = 0,
    DaDuyet = 1,
    Huy = 2,
    TuChoi = 3,
}
// Trạng thái giao dịch 
export enum PointStatus {
    ChuaXuLy = 0,
    DangXuLy = 1,
    Huy = 2,
    KTDuyetThanhCong = 3,
    KTDuyetThatBai = 4,
    AdminDuyetThanhCong = 5,
    AdminDuyetThatBai = 6,
    Loi = 7,
    ThanhCong = 8
}

// // Chốt đơn hoặc hủy đơn
// export enum ActionsOrderType {
//     ConfirmedOrder = 1,     // Chốt đơn
//     CancelOrder = 2,  // Hủy đơn
//     CreateLogisticsOrder = 20,   // Đăng đơn
//     CancelLogisticsOrder = 4,   // Hủy đăng đơn
//     ChangeContactCode = 5,  // Đổi mã vận đơn
//     SyncLogisticStatus = 6,  // Cập nhật trạng thái giao hàng mới
//     CancelContactForBusinesses = 7  // Hủy chốt đơn
// }

export enum EnumOrderStatus {
    DaXacNhan = 1,
    DangChuanBiHang = 2,
    HoanGiaoHang = 3,
    DaChuanBiHangXong = 20,
    DaLayHang = 21,
    KhongLayDuocHang = 22,
    DangGiaoHang = 30,
    DaGiaoHang = 31,
    KhongGiaoDuoc = 33,
    YeuCauGiaoLai = 34,
    DangHoan = 40,
    DaHoan = 41,
    HuyDon = 999,
    ChuaXacNhan = 1000
}

export enum PromotionType {
    TongTien = 1,
    SanPham = 2,
    Code = 3,
    GiamTrucTiepTrenDonHang = 4,
}

export enum UserType {
    TrinhDuocVien = 1,
    DoanhNghiep = 2,
    Kho = 3,
    NhaThuoc = 4,
}

export enum PositionType {
    ChuSoHuu = 1,
    GiamSat = 2,
    NhanVien = 3,
    KeToan = 4
}

// Danh mục của từng loại
export enum CustomerTypeOption {
    IdCustomerType = 1, // Loại khách hàng
    IdGroupNT = 2,    // Nhóm NT
    // IdGroupTDV = 3,    // Nhóm TDV => Đổi sang dùng idOrganization
    idPharmacyType = 4,  // Loại hình nhà thuốc
}

export enum RoleCode {
    CSKH = 'CSKH',
    CTV = 'CTV',
    SHOP = 'SHOP',
    CUS = 'CUS',
    DN = 'DN',
    DNKT = 'DNKT',
    DNKHO = 'DNKHO',
    DNSALE = 'DNSALE',
    SUPPORT = 'SUPPORT',
    KT = 'KT',
    NCC = 'NCC',
    Admin = 'Admin',
    TDV = 'TDV'
}

export enum ServerToSyncConst {
    Odoo = 'Odoo',
    Grab = 'Grab',
    Be = "Be"
}


// export class UsersType {
//     public static TrinhDuocVien = 1;
//     public static DoanhNghiep = 2;
//     public static Kho = 3;
//     public static NhaThuoc = 4;
// }

// export const UsersType = {
//     TrinhDuocVien: 1,
//     DoanhNghiep: 2,
//     Kho: 3,
//     NhaThuoc: 4,
// };

