import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserAddressService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/UserAddress`);
    }
    GetsByUserId(UserId) {
        const queryString = `${this.serviceUri}?UserId=${UserId}`;
        return this.defaultGet(queryString);
    }
    GetsAddressByUserId(key, id, offset, limit, sortField, isAsc) {
        const queryString = `${this.serviceUri}/GetsAddressByUserId?key=${key}&userId=${id}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    SetDefaultAddress(id, userId) {
        const queryString = `${this.serviceUri}/SetDefaultAddress?id=${id}&userId=${userId}`;
        return this.defaultPost(queryString, {});
    }
    GetByUserIdEditOrder(userId) {
        const queryString = `${this.serviceUri}/GetByUserIdEditOrder?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    GetDefault(userId) {
        const queryString = `${this.serviceUri}/GetDefault?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    GetIsSyn() {
        const queryString = `${this.serviceUri}/GetIsSyn?limit=10`;
        return this.defaultGet(queryString);
    }
    AdminDeleteAddress(id) {
        const queryString = `${this.serviceUri}/AdminDeleteAdress?id=${id}`;
        return this.defaultDelete(queryString);
    }
    GetDetailAddress(id) {
        const queryString = `${this.serviceUri}/GetDetailAddress/${id}`;
        return this.defaultGet(queryString);
    }
    AdminSave(form) {
        const queryString = `${this.serviceUri}/AdminSave`;
        return this.defaultPost(queryString, form);
    }
}
UserAddressService.ngInjectableDef = i0.defineInjectable({ factory: function UserAddressService_Factory() { return new UserAddressService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: UserAddressService, providedIn: "root" });
