import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { ProvincesService } from '../../../../dapfood/services/provinces.service';
import { DistrictsService } from '../../../../dapfood/services/districts.service';
import { WardsService } from '../../../../dapfood/services/wards.service';
import { UserAddressService } from '../../../../dapfood/services/useraddress.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Utilities } from '../../../../shared/utilities';
export class UserAddressDeliveryEditComponent extends SecondPageEditBase {
    constructor(_injector, _ProvincesService, _DistrictsService, _WardsService, _configurationService, _userAddress, config, ref, dialogService) {
        super(null, _injector);
        this._injector = _injector;
        this._ProvincesService = _ProvincesService;
        this._DistrictsService = _DistrictsService;
        this._WardsService = _WardsService;
        this._configurationService = _configurationService;
        this._userAddress = _userAddress;
        this.config = config;
        this.ref = ref;
        this.dialogService = dialogService;
        this.isLoading = false;
        this.searchModel = {
            key: '',
            fromDateCustomer: '',
            toDateCustomer: '',
            status: '',
            idProvince: -1,
        };
        this.submitted = false;
        this.cols2 = [];
        this.cols = [];
        this.province_options = [];
        this.district_options = [];
        this.ward_options = [];
        this.idAddress = 0;
        this.userId = 0;
        this.modelEdit = {};
        this.isView = false;
        this.pageCustomer = 1;
        this.limitCustomer = 100;
        this.index = 0;
        this.listItemNumberPerPage = [
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
            { label: '200', value: 200 },
            { label: '500', value: 500 },
            { label: '1000', value: 1000 },
        ];
        this.list_statusType = [];
        this.totalCustomer = 0;
        this.dataTotalCustomer = [];
        this.status_options = [];
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.vi = this._configurationService.calendarVietnamese;
            this.idAddress = this.config.data.idAddress;
            this.fullAddress = this.config.data.fullAddress;
            this.userId = this.config.data.userId;
            this.formGroup = new FormGroup({
                name: new FormControl('', Validators.compose([Validators.required])),
                phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
                idProvince: new FormControl(''),
                idDistrict: new FormControl(''),
                idWard: new FormControl(''),
                address: new FormControl(''),
                code: new FormControl(''),
            });
            yield this.GetDetailAddress(this.idAddress);
            yield this.onLoadDistricts();
            yield this.onLoadProvinces();
            yield this.onLoadWards();
        });
    }
    GetDetailAddress(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (id > 0) {
                yield this._userAddress.GetDetailAddress(id).then(rs => {
                    if (rs.status) {
                        this.modelEdit = rs.data[0];
                    }
                });
            }
        });
    }
    onLoadDistricts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._DistrictsService.GetShort(this.modelEdit.idProvince).then(rs => {
                if (rs.status) {
                    this.district_options = rs.data;
                }
            });
        });
    }
    onLoadProvinces() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._ProvincesService.GetShort().then(rs => {
                if (rs.status) {
                    this.province_options = rs.data;
                }
            });
        });
    }
    onLoadWards() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._WardsService.GetShort(this.modelEdit.idDistrict).then(rs => {
                if (rs.status) {
                    this.ward_options = rs.data;
                }
            });
        });
    }
    save() {
        this.isLoading = true;
        this.submitted = true;
        if (this.formGroup.valid) {
            this.modelEdit.phone = this.modelEdit.phone; // this.f.userName.setValue(this.f.phone.value);
            this.modelEdit.name = this.modelEdit.name; // this.f.userName.setValue(this.f.name.value);
            this.modelEdit.userId = this.userId;
            this.modelEdit.id = this.idAddress;
            this._userAddress.AdminSave(this.modelEdit).then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (response.status) {
                    if (response.data != null && response.data.userId > 0) {
                        this.modelEdit.userId = response.data.userId;
                        this.modelEdit.isDeleted = 0;
                        this.modelEdit.id = response.data.id;
                        this._notifierService.showSuccess(response.message);
                        this.ref.close(this.modelEdit.id);
                    }
                }
                else {
                    this.isLoading = false;
                    this._notifierService.showWarning('Cập nhật địa chỉ giao hàng không thành công!!!.\n' + response.message);
                }
            }))
                .catch(error => {
                this.isLoading = false;
                this._notifierService.showWarning(Utilities.getErrorDescription(error));
            });
        }
        this.isLoading = false;
    }
}
