import { OnInit } from '@angular/core';
import { FileUpload } from 'primeng/primeng';
import { ComponentBase } from '../../../classes/base/component-base';
export class VsViewImageComponent extends ComponentBase {
    constructor() {
        super(...arguments);
        this.file = '';
        this.width = 80;
        this.height = 80;
        this.class = '';
        this.size = 'avatar';
        this.defaultNoImageUrl = 'assets/images/no_image.jpg';
        this.rawFileName = '';
        this.apiUrl = '';
        this.uploading = false;
    }
    // constructor(
    // ) {
    // }
    ngOnInit() { }
    getImageUrl() {
        if (this.file) {
            if (this.file.indexOf('=') > 0) {
                return this.file;
            }
            else {
                if (this.size === 'avatar' || this.size === 'a') {
                    return this.getImageAvatar(this.file);
                }
                else if (this.size === 'medium' || this.size === 'm') {
                    return this.getImageMedium(this.file);
                }
                else if (this.size === 'large' || this.size === 'l') {
                    return this.getImageLarge(this.file);
                }
                else if (this.size === 'original' || this.size === 'o') {
                    return this.getImageOriginal(this.file);
                }
            }
        }
        else {
            return this.defaultNoImageUrl;
        }
    }
    onError(ev) {
        ev.srcElement.src = this.defaultNoImageUrl;
    }
}
