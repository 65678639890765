import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HtMenuService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/HtMenu`);
    }
    getByIdPhanHe(idPhanHe, idCha) {
        const url = `${this.serviceUri}/GetByIdPhanHe?idPhanHe=${idPhanHe}&idCha=${idCha}`;
        return this.defaultGet(url);
    }
    getDetailById(id) {
        const url = `${this.serviceUri}/GetDetailById?id=${id}`;
        return this.defaultGet(url);
    }
    searchTree(phanHeId, trangThai) {
        const url = `${this.serviceUri}/searchTree?phanHeId=${phanHeId}&trangThai=${trangThai}`;
        return this.defaultGet(url);
    }
    deleteCustom(id) {
        const url = `${this.serviceUri}/deleteCustom/${id}`;
        return this._http.put(url, { id: id })
            .pipe(catchError((err) => this.handleError(err, this._injector)))
            .toPromise();
    }
    pinMenuItem(idMenu) {
        const url = `${this.serviceUri}/PinMenuItem?menuId=${idMenu}`;
        return this._http
            .post(url, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    getsPinMenu(idPhanHe) {
        const url = `${this.serviceUri}/GetsPinMenu?phanHeId=${idPhanHe}`;
        return this.defaultGet(url);
    }
    unPinMenuItem(idMenu) {
        const url = `${this.serviceUri}/UnPinMenuItem?id=${idMenu}`;
        return this._http
            .post(url, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
HtMenuService.ngInjectableDef = i0.defineInjectable({ factory: function HtMenuService_Factory() { return new HtMenuService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: HtMenuService, providedIn: "root" });
