import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Utilities } from '../shared/utilities';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { ProvincesService } from '../dapfood/services/provinces.service';
import { DistrictsService } from '../dapfood/services/districts.service';
import { WardsService } from '../dapfood/services/wards.service';
import { UserAddressService } from '../dapfood/services/useraddress.service';
export class ProfileAddressComponent extends SecondPageEditBase {
    constructor(_injector, formBuilder, _UserAddressService, _ProvincesService, _DistrictsService, _WardsService) {
        super(null, _injector);
        this._injector = _injector;
        this.formBuilder = formBuilder;
        this._UserAddressService = _UserAddressService;
        this._ProvincesService = _ProvincesService;
        this._DistrictsService = _DistrictsService;
        this._WardsService = _WardsService;
        this.USER_INFO_KEY = 'user_info';
        this.modelEdit = {};
        this.isLoading = false;
        this.isSaving = false;
        this.isView = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.formGroup = new FormGroup({
                idProvince: new FormControl('', Validators.compose([Validators.required])),
                idDistrict: new FormControl('', Validators.compose([Validators.required])),
                idWard: new FormControl('', Validators.compose([Validators.required])),
                address: new FormControl('', Validators.compose([Validators.required])),
                isPrimary: new FormControl(''),
            });
        });
    }
    save() {
        this.isSaving = true;
        if (this.modelEdit.userId > 0) {
            this._UserAddressService.post(this.modelEdit)
                .then(response => {
                if (response.status) {
                    this._notifierService.showSuccess('Cập nhật địa chỉ thành công!');
                    this.isShow = false;
                    this.closePopup.emit();
                }
                else {
                    this.isSaving = false;
                    this._notifierService.showWarning('Cập nhật thông tin địa chỉ thất bại.\nNội dung lỗi: ' + response.message);
                }
            })
                .catch(error => {
                this.isSaving = false;
                this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
            });
        }
    }
    showPopup(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isShow = true;
            this.modelEdit = {};
            this.modelEdit.userId = item.userId;
            yield this.onLoadProvinces();
            if (item.id > 0) {
                this.isLoading = true;
                this.item = item.id;
                this.isView = true;
                this._UserAddressService
                    .getDetail(item.id)
                    .then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.isLoading = false;
                    if (response.status) {
                        this.modelEdit = response.data;
                        yield this.onLoadDistricts();
                        yield this.onLoadWards();
                    }
                }))
                    .catch(error => {
                    this.isLoading = false;
                    this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
                });
            }
        });
    }
    togglePopupDelete() {
        this.modelEdit = {};
    }
    onLoadProvinces() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._ProvincesService.GetShort().then(rs => {
                if (rs.status) {
                    this.province_options = rs.data;
                }
            });
        });
    }
    onLoadDistricts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._DistrictsService.GetShort(this.modelEdit.idProvince).then(rs => {
                if (rs.status) {
                    this.distric_options = rs.data;
                }
            });
        });
    }
    onLoadWards() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._WardsService.GetShort(this.modelEdit.idDistrict).then(rs => {
                if (rs.status) {
                    this.ward_options = rs.data;
                }
            });
        });
    }
}
