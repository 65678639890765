/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vs-avatar-view.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../user-view/user-view.component.ngfactory";
import * as i4 from "../user-view/user-view.component";
import * as i5 from "../../../services/user-global.service";
import * as i6 from "primeng/components/dynamicdialog/dynamicdialog-config";
import * as i7 from "primeng/components/dynamicdialog/dynamicdialog-ref";
import * as i8 from "@angular/forms";
import * as i9 from "./vs-avatar-view.component";
var styles_VsAvatarViewComponent = [i0.styles];
var RenderType_VsAvatarViewComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_VsAvatarViewComponent, data: {} });
export { RenderType_VsAvatarViewComponent as RenderType_VsAvatarViewComponent };
function View_VsAvatarViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "width": 0, "height": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, (_co.size + "px"), (_co.size + "px")); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getImageAvatar(_co.model.avatar), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_VsAvatarViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "initials"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "font-size": 0 }), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.fontsize + "px")); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.model.initName; _ck(_v, 3, 0, currVal_1); }); }
function View_VsAvatarViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "popup_user"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "popup_top": 0, "popup_bottom": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-user-view", [], null, null, null, i3.View_UserViewComponent_0, i3.RenderType_UserViewComponent)), i1.ɵdid(4, 245760, null, 0, i4.UserViewComponent, [i1.Injector, i5.UserGlobalService, i6.DynamicDialogConfig, i7.DynamicDialogRef], { userId: [0, "userId"], showData: [1, "showData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popup_user"; var currVal_1 = _ck(_v, 2, 0, (_co.indexTable > 3), (_co.indexTable <= 3)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.model.userId; var currVal_3 = _co.showPopup[_co.indexTable]; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_VsAvatarViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "circle"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.showPopup[_co.indexTable] = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_co.showPopup[_co.indexTable] = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0, "width": 1, "height": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsAvatarViewComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsAvatarViewComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsAvatarViewComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.model.color, (_co.size + "px"), (_co.size + "px")); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.showInitials; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.showInitials; _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.showPopup[_co.indexTable] && !_co.mouseLeave) && _co.isShowMess); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_VsAvatarViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "vs-avatar-view", [], null, null, null, View_VsAvatarViewComponent_0, RenderType_VsAvatarViewComponent)), i1.ɵprd(5120, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.VsAvatarViewComponent]), i1.ɵdid(2, 245760, null, 0, i9.VsAvatarViewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var VsAvatarViewComponentNgFactory = i1.ɵccf("vs-avatar-view", i9.VsAvatarViewComponent, View_VsAvatarViewComponent_Host_0, { size: "size", fontsize: "fontsize", indexTable: "indexTable", mouseLeave: "mouseLeave", isShowMess: "isShowMess" }, {}, []);
export { VsAvatarViewComponentNgFactory as VsAvatarViewComponentNgFactory };
