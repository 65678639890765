import { Component, Injector, Input, OnInit } from '@angular/core';
import { SecondPageEditBase } from '../../classes/base/second-page-edit-base';
import { UserGlobalService } from '../../../services/user-global.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent extends SecondPageEditBase implements OnInit {

  @Input() userId: number;
  @Input() showData: boolean

  modelEdit: any = {};
  constructor(
    protected _injector: Injector,
    public _userService: UserGlobalService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
    super(null, _injector);
  }

  async ngOnInit() {
    if (this.showData)
      await this.getDetailUser(this.userId);
  }

  async getDetailUser(id) {
    await this._userService.GetDetailInfoUser(id).then(rs => {
      if (rs.status) {
        this.modelEdit = rs.data;
        // this.modelEdit.avatar = this.getImageAvatar(this.modelEdit?.avatar);
      }
    });
  }

  urlMess() {
    return `/chat/${this.userId}`
  }

}
