export class User {
    firstName: string;
    lastName: string;
    displayName: string;
    fullName: string;
    email: string;
    issuperuser: boolean;
    permissions: any;
    roleassign: any;
    scope: any;
    avatar: string;
    id: number;
    idClient: number;
    idShop: number;
    userId: number;
    userName: string;
    name: string;
    phone: string;
    idProvince: number;
    idDistrict: number;
    idWard: number;
    address: string;
    isDeleted: boolean;
    idBank: number;
    bankNumber: string;
    bankCardNumber: string;
    bankFullName: string;
    bankCode: string;
    bankBranch: string;
    idBankNavigation: any;
    balance: any;
    cashInTransit: any;
    idPortal: any;
    omiCallSipUser: number;
    omiCallSecretKey: string;
    omiCallDomain: string;
    isOmiCall: boolean;
}
