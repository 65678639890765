/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vs-file-viewer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./vs-file-viewer.component";
import * as i3 from "primeng/components/dynamicdialog/dynamicdialog-config";
import * as i4 from "primeng/components/dynamicdialog/dynamicdialog-ref";
var styles_VsFileViewerComponent = [i0.styles];
var RenderType_VsFileViewerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_VsFileViewerComponent, data: {} });
export { RenderType_VsFileViewerComponent as RenderType_VsFileViewerComponent };
export function View_VsFileViewerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "height: calc(100vh - 48px); width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "fileviewer"]], null, null, null, null, null))], null, null); }
export function View_VsFileViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vs-file-viewer", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_VsFileViewerComponent_0, RenderType_VsFileViewerComponent)), i1.ɵdid(1, 245760, null, 0, i2.VsFileViewerComponent, [i3.DynamicDialogConfig, i4.DynamicDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VsFileViewerComponentNgFactory = i1.ɵccf("app-vs-file-viewer", i2.VsFileViewerComponent, View_VsFileViewerComponent_Host_0, {}, {}, []);
export { VsFileViewerComponentNgFactory as VsFileViewerComponentNgFactory };
