import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from '../../classes/base/second-page-edit-base';
import { ProvincesService } from '../../../dapfood/services/provinces.service';
import { DistrictsService } from '../../../dapfood/services/districts.service';
import { CustomerTypesService } from '../../../dapfood/services/customerTypes.service';
import { WardsService } from '../../../dapfood/services/wards.service';
import { UserService } from '../../services/user.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../../shared/utilities';
import { CustomerTypeOption, UserType } from '../../../dapfood/common/constant';
import { UsersTempService } from '../../../dapfood/services/userstemp.service';
import { forEach } from '@angular/router/src/utils/collection';
import * as moment from 'moment';
import { DetailAddress } from '../../models/detailAddress.model';

@Component({
  selector: 'app-user-information-tdv-edit',
  templateUrl: './user-information-tdv-edit.component.html',
  styleUrls: ['./user-information-tdv-edit.component.scss']
})
export class UserInformationTdvEditComponent extends SecondPageEditBase implements OnInit {
  formGroup: FormGroup;
  formGroupTdv: FormGroup;
  isShow = false;
  customerTypes_options: any = [];
  district_options: any = [];
  userGroups_options: any = [];
  pharmacyType_options: any = [];
  tdvGroups_options = [];
  ward_options: any = [];
  district_options_temp: any = [];
  ward_options_temp: any = [];
  placeOfIssueDkdn_options: any = [];
  province_options: any = [];
  isLoading = false;
  submitted = false;
  isCheckCompared: boolean = false;
  isGetLocation: boolean = false;
  addressLatLng: string = "";

  isGetLocation_old: boolean = false;
  addressLatLng_old: string = "";

  modelEdit: any = {
    userId: 0,
    name: "",
    idType: -1
  };

  originalModelEdit : any = {
    userId: 0,
    name: "",
    idType: -1
  };

  compareDistance: number;

  isFormConfirmEdit: boolean = false;
  modelEditByTdv: any = {};

  dataDifference: any = {};

  isCreate = false;
  UserType = UserType;
  accountType_options = [];
  isShowAccountType = false;

  @Input() isFormTdv = false;
  @Input() titleHeader: string = "Thêm/Sửa nhà thuốc";

  constructor(
    protected _injector: Injector,
    private _ProvincesService: ProvincesService,
    private _DistrictsService: DistrictsService,
    private _customerTypesService: CustomerTypesService,
    private _WardsService: WardsService,
    private _UserService: UserService,
    private _UsersTempService: UsersTempService,
    private _configurationService: ConfigurationService,

  ) {
    super(null, _injector);
  }

  async ngOnInit() {
    this.init();
  }

  async init() {
    this.vi = this._configurationService.calendarVietnamese;
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      password: new FormControl(''),  //, Validators.compose([Validators.required, Validators.minLength(6)])
      email: new FormControl(''),
      code: new FormControl(''),
      avatar: new FormControl(''),
      legalRepresentatives: new FormControl(''),
      pharmacistPtcm: new FormControl(''),
      idCardPtcm: new FormControl(''),
      phonePtcm: new FormControl(''),
      idProvince: new FormControl(''),
      idDistrict: new FormControl(''),
      idWard: new FormControl(''),
      address: new FormControl(''),
      certificateNumberDkdn: new FormControl(''),
      certificateDkdnDate: new FormControl(''),
      placeOfIssueDkdn: new FormControl(''),
      certificateDkdn: new FormControl(''),
      certificateNumberDdkkd: new FormControl(''),
      certificateDdkkdDate: new FormControl(''),
      placeOfIssueDdkkd: new FormControl(''),
      certificateDdkkd: new FormControl(''),
      certificateNumberGdpGppDsp: new FormControl(''),
      certificateGdpGppDspDate: new FormControl(''),
      placeOfIssueGdpGppDsp: new FormControl(''),
      certificateGdpGppDsp: new FormControl(''),
      idPharmacyType: new FormControl('', Validators.compose([Validators.required])),
      userCustomerTypes: new FormControl(''),
      userName: new FormControl(''),
      passwordRepeat: new FormControl(''),
      idCustomerType: new FormControl(''),
      invoiceCompanyName: new FormControl(''),
      invoiceEmail: new FormControl(''),
      invoiceTaxCode: new FormControl(''),
      invoiceAddress: new FormControl(''),
      idGroup: new FormControl(''),
      idType: new FormControl(''),
      lat: new FormControl(''),
      lng: new FormControl(''),
    });

    this.formGroupTdv = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      password: new FormControl(''),  //, Validators.compose([Validators.required, Validators.minLength(6)])
      email: new FormControl(''),
      code: new FormControl(''),
      avatar: new FormControl(''),
      legalRepresentatives: new FormControl(''),
      pharmacistPtcm: new FormControl(''),
      idCardPtcm: new FormControl(''),
      phonePtcm: new FormControl(''),
      idProvince: new FormControl(''),
      idDistrict: new FormControl(''),
      idWard: new FormControl(''),
      address: new FormControl(''),
      certificateNumberDkdn: new FormControl(''),
      certificateDkdnDate: new FormControl(''),
      placeOfIssueDkdn: new FormControl(''),
      certificateDkdn: new FormControl(''),
      certificateNumberDdkkd: new FormControl(''),
      certificateDdkkdDate: new FormControl(''),
      placeOfIssueDdkkd: new FormControl(''),
      certificateDdkkd: new FormControl(''),
      certificateNumberGdpGppDsp: new FormControl(''),
      certificateGdpGppDspDate: new FormControl(''),
      placeOfIssueGdpGppDsp: new FormControl(''),
      certificateGdpGppDsp: new FormControl(''),
      idPharmacyType: new FormControl('', Validators.compose([Validators.required])),
      userCustomerTypes: new FormControl(''),
      userName: new FormControl(''),
      passwordRepeat: new FormControl(''),
      idCustomerType: new FormControl(''),
      idGroup: new FormControl(''),
      invoiceCompanyName: new FormControl(''),
      invoiceEmail: new FormControl(''),
      invoiceTaxCode: new FormControl(''),
      invoiceAddress: new FormControl(''),
      idType: new FormControl(''),
      lat: new FormControl(''),
      lng: new FormControl(''),
    });

    await this.loadUserTypes(); // Loại khách hàng
    await this.loadPharmacyTypes();

    this.accountType_options = [
      { label: 'Loại tài khoản', value: -1 },
      { label: 'Nhà thuốc', value: UserType.NhaThuoc },
      { label: 'Trình dược viên', value: UserType.TrinhDuocVien },
      { label: 'Doanh nghiệp', value: UserType.DoanhNghiep },
      { label: 'Kho', value: UserType.Kho },
    ]
  }

  async showPopup(data) {
    this.isFormConfirmEdit = data.isFormConfirmEdit;
    this.modelEdit = {};
    this.originalModelEdit = {};
    this.isCheckCompared = false;
    this.modelEditByTdv = {};
    this.isShow = true;
    this.submitted = false;
    this.modelEdit.idType = data.idType;
    // Reset lại các checkbox khi mở popup
    this.resetAllCheckboxes();
    this.formGroup.reset();
    this.formGroupTdv.reset();

    this.isShowAccountType = data.isShowAccountType;
    this.dataDifference = {};
    await this.onLoadProvinces();

    if (data.id > 0) {

      this.formGroup.get('password').clearValidators();
      this.formGroup.get('password').updateValueAndValidity();

      // await this._UserService.GetByUserId(data.id).then(async rs => {
      //   if (rs.status) {
      //     this.modelEdit = rs.data;
      //     if (this.modelEdit.certificateDkdnDate) {
      //       this.modelEdit.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
      //     }
      //     if (this.modelEdit.certificateGdpGppDspDate) {
      //       this.modelEdit.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
      //     }
      //     if (this.modelEdit.certificateDdkkdDate) {
      //       this.modelEdit.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
      //     }
      //   }
      // }, () => {
      //   this._notifierService.showHttpUnknowError();
      // });
      //  await this._UserService.GetUserTempById(data.id).then(async rs => {
      //   if (rs.status) {
      //     this.modelEditByTdv = rs.data;
      //     if (this.modelEditByTdv.certificateDkdnDate) {
      //       this.modelEditByTdv.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
      //     }
      //     if (this.modelEditByTdv.certificateGdpGppDspDate) {
      //       this.modelEditByTdv.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
      //     }
      //     if (this.modelEditByTdv.certificateDdkkdDate) {
      //       this.modelEditByTdv.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
      //     }
      //   }
      // }
      // , () => {
      //   this._notifierService.showHttpUnknowError();
      // });
      await this._UsersTempService.CompareTwoTableUserAndUserTemp(data.id).then(async rs => {
        if (rs.status) {
          this.modelEdit = rs.data.user;
          this.originalModelEdit = { ...this.modelEdit };
          this.modelEditByTdv = rs.data.userTemp;
          this.dataDifference = rs.data.differences;
          this.modelEditByTdv.avatar = rs.data.userTemp.avatar;
          if (this.modelEdit.certificateDkdnDate) {
            this.modelEdit.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
          }
          if (this.modelEdit.certificateGdpGppDspDate) {
            this.modelEdit.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
          }
          if (this.modelEdit.certificateDdkkdDate) {
            this.modelEdit.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
          }

          if (this.modelEditByTdv.certificateDkdnDate) {
            this.modelEditByTdv.certificateDkdnDate = new Date(this.modelEditByTdv.certificateDkdnDate);
          }
          if (this.modelEditByTdv.certificateGdpGppDspDate) {
            this.modelEditByTdv.certificateGdpGppDspDate = new Date(this.modelEditByTdv.certificateGdpGppDspDate);
          }
          if (this.modelEditByTdv.certificateDdkkdDate) {
            this.modelEditByTdv.certificateDdkkdDate = new Date(this.modelEditByTdv.certificateDdkkdDate);
          }

        }
      }, () => {
        this._notifierService.showHttpUnknowError();
      });

      // if (data.tdvEditTemp != null && data.tdvEditTemp > 0) {
      //   this.formGroup.addControl('isEditTdvTemp ', new FormControl(data.tdvEditTemp));
      //   this.modelEdit.isEditTdvTemp = data.tdvEditTemp
      // }

    } else {
      this.formGroup.get('password').setValidators([Validators.required, Validators.minLength(6)]);
      this.formGroup.get('password').updateValueAndValidity();
      this.modelEdit = {
        userId: 0,
        idType: data.idType
      };
      this.isCreate = true;
    }

    if (this.modelEdit.idType === UserType.NhaThuoc) {
      this.formGroup.get('idPharmacyType').setValidators([Validators.required]);
    } else {
      this.formGroup.get('idPharmacyType').clearValidators();
    }
    this.formGroup.get('idPharmacyType').updateValueAndValidity();
    if (data.idType === -1 && data.id <= 0) {
      this.formGroup.get('accountType').setValidators([Validators.required]);
      this.formGroup.get('accountType').updateValueAndValidity();
    }

    if (this.modelEdit.idType === UserType.NhaThuoc)
      await this.loadUserGroupsNT();
    this.onLoadDistricts();
    this.onLoadWards();
    this.onLoadDistrictsTemp();
    this.onLoadWardsTemp();
  }

  // load bản cập nhật do tdv update

  //loại khách hàng
  async loadUserTypes() {
    this.customerTypes_options = [];
    await this._customerTypesService.GetShort('', CustomerTypeOption.IdCustomerType, 0, 10000).then(rs => {
      if (rs.status) {
        let dataArr: any = [];
        rs.data.forEach(item => {
          dataArr.push({ label: item.name, value: item.id });
        });
        this.customerTypes_options = dataArr;
      }
    });
  }

  //loại hình nhà thuốc
  async loadPharmacyTypes() {
    this.pharmacyType_options = [];
    await this._customerTypesService.GetShort('', CustomerTypeOption.idPharmacyType, 0, 10000).then(rs => {
      if (rs.status) {
        rs.data.forEach(item => {
          this.pharmacyType_options.push({ label: item.name, value: item.id });
        });
      }
    });
  }

  async onLoadDistricts() {
    await this._DistrictsService.GetShort(this.modelEdit.idProvince).then(rs => {
      if (rs.status) {
        this.district_options = rs.data;
      }
    });
  }

  async onLoadProvinces() {
    await this._ProvincesService.GetShort().then(rs => {
      if (rs.status) {
        this.province_options = rs.data;
        this.placeOfIssueDkdn_options = rs.data;
      }
    });
  }

  async onLoadWards() {
    await this._WardsService.GetShort(this.modelEdit.idDistrict).then(rs => {
      if (rs.status) {
        this.ward_options = rs.data;
      }
    });
  }

  async onLoadDistrictsTemp() {
    await this._DistrictsService.GetShort(this.modelEditByTdv.idProvince).then(rs => {
      if (rs.status) {
        this.district_options_temp = rs.data;
      }
    });
  }

  async onLoadWardsTemp() {
    await this._WardsService.GetShort(this.modelEditByTdv.idDistrict).then(rs => {
      if (rs.status) {
        this.ward_options_temp = rs.data;
      }
    });
  }

  //nhóm khách hàng
  async loadUserGroupsNT() {
    await this._customerTypesService.GetShort('', CustomerTypeOption.IdGroupNT, 0, 10000).then(rs => {
      if (rs.status) {
        var list: any = [];
        rs.data.forEach(item => {
          list.push({ label: item.name, value: item.id });
        });
        this.userGroups_options = list;
      }
    });
  }

  save() {
    this.isLoading = true;
    this.submitted = true;
  
    if (this.formGroup.valid) {
      this.modelEdit.username = this.modelEdit.phone; 
      this.modelEdit.passwordRepeat = this.modelEdit.password; 
      let _userCustomerTypes = [];
  
      this.modelEdit.userCustomerTypes = _userCustomerTypes; 
      if (this.isFormConfirmEdit) {
        this.formGroup.addControl('confirmUpdate', new FormControl('', Validators.required));
        this.modelEdit.confirmUpdate = true;
      }

      this._UserService.post(this.modelEdit).then(async (response) => {
        if (response.status) {
          if (response.data != null && response.data.userId > 0) {
            this.modelEdit.userId = response.data.userId;
            this.modelEdit.isDeleted = 0;
  
            this._notifierService.showSuccess(response.message);
            this.closePopupMethod(response.data);
          }
        } else {
          this.isLoading = false;
          this._notifierService.showWarning('Cập nhật thông tin người dùng thất bại.\n' + response.message);
        }
      })
      .catch(error => {
        this.isLoading = false;
        this._notifierService.showWarning(Utilities.getErrorDescription(error));
      });
    } else {
      const invalid = this.findInvalidControls();
      this._notifierService.showError(invalid, 'Vui lòng nhập đủ thông tin');
    }
  
    this.isLoading = false;
  }
  

  findInvalidControls() {
    const invalidFields = [];
    const controls = this.formGroup.controls;
  
    for (const name in controls) {
      if (controls[name].invalid) {
        const controlTitle = this.getControlTitle(name) || 'invalid'; // Kiểm tra nếu không có tiêu đề thì gán giá trị 'invalid'
        const ce = controls[name].errors;
  
        if (ce.required) {
          if (controlTitle === 'invalid') {
            invalidFields.push('Vui lòng F5 lại trang.');
          } else {
            invalidFields.push(`Vui lòng nhập ${controlTitle}`);
          }
        }
  
        if (ce.minlength) {
          if (controlTitle === 'invalid') {
            invalidFields.push('Vui lòng làm mới lại trang.');
          } else {
            invalidFields.push(`${controlTitle} ít nhất ${ce.minlength.requiredLength} ký tự`);
          }
        }
  
        if (ce.email) {
          if (controlTitle === 'invalid') {
            invalidFields.push('Vui lòng làm mới lại trang.');
          } else {
            invalidFields.push(`${controlTitle} sai định dạng`);
          }
        }
      }
    }
  
    return invalidFields.length ? invalidFields.join(', ') : '';
  }
  

  closePopupMethod(data: any) {
    this.formGroup.reset();
    this.formGroupTdv.reset();
    this.resetAllCheckboxes();
    this.isCheckCompared = false;
    this.isShow = false;
    this.closePopup.next(data);
  }

  getControlTitle(name) {
    switch (name) {
      case 'name':
        return 'Tên nhà thuốc/phòng khám/TDV';
      case 'phone':
        return 'Số điện thoại';
      case 'password':
        return 'Mật khẩu';
      // case 'email':
      //   return 'Email';
      case 'idPharmacyType':
        return 'Loại tài khoản';
    }
  }

  accountTypeChange() {
    if (this.modelEdit.idType === UserType.NhaThuoc) {
      this.formGroup.get('idPharmacyType').setValidators([Validators.required]);
      this.loadUserGroupsNT();
    } else {
      this.formGroup.get('idPharmacyType').clearValidators();
    }
    this.formGroup.get('idPharmacyType').updateValueAndValidity();
  }

  onCheckboxChange(event: Event) {
    var checkbox = event.target as HTMLInputElement;
    var controlName = checkbox.getAttribute('data-control-name');
  
    if (controlName) {
      if (checkbox.checked) {
        if (controlName === 'location') {
          this.modelEdit.idProvince = this.modelEditByTdv.idProvince;
          this.modelEdit.idDistrict = this.modelEditByTdv.idDistrict;
          this.modelEdit.idWard = this.modelEditByTdv.idWard;
          this.onLoadDistricts();
          this.onLoadWards();
        } else if (controlName === 'locateLatLng') {
          this.modelEdit.lat = this.modelEditByTdv.lat;
          this.modelEdit.lng = this.modelEditByTdv.lng;
        } else {
          this.modelEdit[controlName] = this.modelEditByTdv[controlName];
        }
      } else {
        if (this.originalModelEdit) {
          if (controlName === 'location') {
            this.modelEdit.idProvince = this.originalModelEdit.idProvince;
            this.modelEdit.idDistrict = this.originalModelEdit.idDistrict;
            this.modelEdit.idWard = this.originalModelEdit.idWard;
            this.onLoadDistricts();
            this.onLoadWards();
          } else if (controlName === 'locateLatLng') {
            this.modelEdit.lat = this.originalModelEdit.lat;
            this.modelEdit.lng = this.originalModelEdit.lng;
          } else {
            this.modelEdit[controlName] = this.originalModelEdit[controlName];
          }
        }
      }
    }
  }
  

  resetAllCheckboxes() {
    const checkboxes = document.querySelectorAll('input[type="checkbox"][data-control-name]') as NodeListOf<HTMLInputElement>;
    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });
    console.log(checkboxes);
  }

  onSelectCompare(event: any) {
    console.log(event);
    
    this.isCheckCompared = event;
  
    if (this.isCheckCompared) {
      const differentFields = Object.keys(this.dataDifference)
        .filter(key => this.dataDifference[key] === false);
  
      differentFields.forEach(field => {
        this.modelEdit[field] = this.modelEditByTdv[field];
        
        if (field === 'lat' || field === 'lng') {
          const checkbox = document.querySelector(`input[type="checkbox"][data-control-name="locateLatLng"]`) as HTMLInputElement;
          if (checkbox) {
            checkbox.checked = true;
          }
        }else if(field === 'idProvince' || field === 'idDistrict' || field === 'idWard') {
          const checkbox = document.querySelector(`input[type="checkbox"][data-control-name="location"]`) as HTMLInputElement;
          if (checkbox) {
            checkbox.checked = true;
            this.onLoadDistricts();
            this.onLoadWards();
          }
        }
        else {
          const checkbox = document.querySelector(`input[type="checkbox"][data-control-name="${field}"]`) as HTMLInputElement;
          if (checkbox) {
            checkbox.checked = true;
          }
        }
      });
    }
  }


  viewLocation(lat: any, lng: any) {
    if (lat && lng) {
      const url = `https://www.google.com/maps?q=${lat},${lng}`;
      window.open(url, '_blank');
    } else {
      alert('Toạ độ không hợp lệ!!!.');
    }
  }
  
  fn_compareDistance(lat_old: number, lng_old: number, lat_new: number, lng_new: number): number {
    const R = 6371; 
  
    const toRadians = (degree: number): number => (degree * Math.PI) / 180;
    // old
    const lat1Rad = toRadians(lat_old);
    const lon1Rad = toRadians(lng_old);

    // new
    const lat2Rad = toRadians(lat_new);
    const lon2Rad = toRadians(lng_new);
  
    const dlat = lat2Rad - lat1Rad;
    const dlon = lon2Rad - lon1Rad;
  
    const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distance = R * c * 1000;
    return parseFloat(distance.toFixed(2));
  }
  
  fn_CheckLocationLatLng(lat: number, lng: number, type: 1 | 2) {
    const getJsonLoccationLatLng = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json&accept-language=vi`;
    
    fetch(getJsonLoccationLatLng)
        .then(response => response.json())
        .then(data => {
            const detailAddress: DetailAddress = this.mapJsonToDetailAddress(data);
            if (type === 2) {
                this.addressLatLng = detailAddress.display_name;
                this.isGetLocation = !this.isGetLocation;
            } else if (type === 1) {
                this.addressLatLng_old = detailAddress.display_name;
                this.isGetLocation_old = !this.isGetLocation_old;
            }
        })
        .catch(error => console.error("CHECK LẠI LAT LNG"));
}


  mapJsonToDetailAddress(data: any): DetailAddress {
      return {
          place_id: data.place_id,
          licence: data.licence,
          osm_type: data.osm_type,
          osm_id: data.osm_id,
          lat: data.lat,
          lon: data.lon,
          class: data.class,
          type: data.type,
          place_rank: data.place_rank,
          importance: data.importance,
          addresstype: data.addresstype,
          name: data.name,
          display_name: data.display_name,
          address: {
              road: data.address.road,
              quarter: data.address.quarter,
              suburb: data.address.suburb,
              city: data.address.city,
              ISO3166_2_lvl4: data.address.ISO3166_2_lvl4,
              postcode: data.address.postcode,
              country: data.address.country,
              country_code: data.address.country_code
          },
          boundingbox: data.boundingbox
      };
  }

}
