<div class="vs-form-container default-control-label-width vs-modal-content-panel cskh-sign-up">
  <div class="vs-form-container default-control-label-width">
    <form action="" [formGroup]="formGroup">
      <div class="ui-g row">
        <p-panel class="ui-g-12" header="Thông tin địa chỉ giao hàng" [toggleable]="true" toggler="header">
          <div class="ui-g row">
            <div class="ui-g-12">
              <div class="ui-g-12 ui-lg-12">
                <div class="vs-form-control ui-g">
                  <div class="__form-control-label ui-g-12 ui-md-4">Mã CODE</div>
                  <div class="__form-control-inputs ui-g-12 ui-md-8">
                    <div class="__input-wrapper required">
                      <input type="text" [(ngModel)]="modelEdit.code" formControlName="code" placeholder="Nhập mã code" />
                      <!-- <div class="form-control-feedback" *ngIf="!formGroup.controls['code'].valid && submitted">Trường thông tin này là bắt buộc!</div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 ui-lg-12">
                <div class="vs-form-control ui-g">
                  <div class="__form-control-label ui-g-12 ui-md-4">Tên người nhận</div>
                  <div class="__form-control-inputs ui-g-12 ui-md-8">
                    <div class="__input-wrapper required">
                      <input type="text" [(ngModel)]="modelEdit.name" formControlName="name" placeholder="Tên người nhận" />
                      <div class="form-control-feedback" *ngIf="!formGroup.controls['name'].valid && submitted">Trường thông tin này là bắt buộc!</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 ui-lg-12">
                <div class="vs-form-control ui-g">
                  <div class="__form-control-label ui-g-12 ui-md-4">Số điện thoại người nhận</div>
                  <div class="__form-control-inputs ui-g-12 ui-md-8">
                    <div class="__input-wrapper required">
                      <input type="text" [(ngModel)]="modelEdit.phone" formControlName="phone" placeholder="Số điện thoại" />
                      <div class="form-control-feedback" *ngIf="!formGroup.controls['phone'].valid && submitted">Trường thông tin này là bắt buộc!</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 ui-lg-12">
                <div class="vs-form-control ui-g">
                  <div class="__form-control-label ui-g-12 ui-md-3">Địa chỉ</div>
                  <div class="__form-control-inputs ui-g-12 ui-md-3 mr-10">
                    <div class="__input-wrapper" style="position: relative;">
                      <p-dropdown [baseZIndex]="15000" formControlName="idProvince" [showClear]="true" filter="true" placeholder="-- Tỉnh/TP --" [emptyFilterMessage]="'Không tìm thấy kết quả'" [(ngModel)]="modelEdit.idProvince" [options]="province_options" [style]="{'width':'100%', 'min-width':'0'}" appendTo="body" (onChange)="onLoadDistricts()"></p-dropdown>
                    </div>
                  </div>
                  <div class="__form-control-inputs ui-g-12 ui-md-3 mr-10">
                    <div class="__input-wrapper" style="position: relative;">
                      <p-dropdown [baseZIndex]="15000" formControlName="idDistrict" [showClear]="true" filter="true" placeholder="-- Quận/Huyện --" [emptyFilterMessage]="'Không tìm thấy kết quả'" [(ngModel)]="modelEdit.idDistrict" [options]="district_options" [style]="{'width':'100%', 'min-width':'0'}" appendTo="body" (onChange)="onLoadWards()"></p-dropdown>
                    </div>
                  </div>
                  <div class="__form-control-inputs ui-g-12 ui-md-3">
                    <div class="__input-wrapper" style="position: relative;">
                      <p-dropdown [baseZIndex]="15000" formControlName="idWard" [showClear]="true" filter="true" placeholder="-- Xã/Phường --" [emptyFilterMessage]="'Không tìm thấy kết quả'" [(ngModel)]="modelEdit.idWard" [options]="ward_options" [style]="{'width':'100%', 'min-width':'0'}" appendTo="body"></p-dropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ui-g-12 ui-lg-12">
                <div class="vs-form-control ui-g">
                  <div class="__form-control-label ui-g-12 ui-md-4">Số nhà</div>
                  <div class="__form-control-inputs ui-g-12 ui-md-8">
                    <div class="__input-wrapper" style="position: relative;">
                      <input type="text" [(ngModel)]="modelEdit.address" formControlName="address" placeholder="Số nhà/đường/ngõ/nghách" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </form>
  </div>
</div>
<div class="ui-g row mt-20 align-center">
  <div class="ui-g-12">
    <button class="vs-btn vs-modal-btn vs-btn-action-edit" [disabled]="isLoading" type="button" (click)="save()">
      <span class="__icon fa fa-floppy-o"></span>
      <span class="__label">Lưu</span>
    </button>
  </div>
</div>