import { Component, Injector, Input, OnInit } from '@angular/core';
import { SecondPageIndexBase } from '../../classes/base/second-page-index-base';
import { EventEmitterService } from '../../../services/eventemitter.service';
import { UserLogService } from '../../services/user-log.service';

@Component({
  selector: 'app-log-user-changes',
  templateUrl: './log-user-changes.component.html',
  styleUrls: ['./log-user-changes.component.scss']
})
export class LogUserChangesComponent extends SecondPageIndexBase implements OnInit {

  @Input() userId: number;
  constructor(
    protected _injector: Injector,
    private _userLogs: UserLogService,
) {
    super(null, _injector);
}

  ngOnInit() {

    this.cols = [
      {
        field: 'invoiceTaxCode',
        header: 'Mã số thuế',
        visible: true,
        align: 'center',
        width: '12%',
      },
      {
        field: 'code',
        header: 'Mã khách hàng',
        visible: true,
        align: 'center',
        width: '10%',
      },
      {
        field: 'name',
        header: 'Tên KH',
        visible: true,
        align: 'center',
        width: '10%',
      },
      {
        field: 'phone',
        header: 'SĐT',
        visible: true,
        align: 'center',
        width: '10%',
      },
      {
        field: 'fullAddress',
        header: 'Địa chỉ',
        visible: true,
        align: 'center',
        width: '30%',
      },
      {
        field: 'lng',
        header: 'Kinh độ',
        visible: true,
        align: 'center',
        width: '10%',
      },
      {
        field: 'lat',
        header: 'Vĩ độ',
        visible: true,
        align: 'center',
        width: '10%',
      }
    ];

    this.getUserLog();
  }

  async getUserLog() {
    if (this.userId) {
      await this._userLogs.LogChangeInfomation(this.userId)
        .then(async response => {
          if (response.status) {
            const data = response.data.reverse();
            this.dataSource = this.markChanges(data);
            this.total = response.totalRecord;
          }
        }, () => {
          this._notifierService.showHttpUnknowError();
        });
    }
  }

markChanges(data: any[]): any[] {
  for (let i = 1; i < data.length; i++) {
    const currentRecord = data[i];
    const previousRecord = data[i - 1];
    
    currentRecord.changes = {};

    for (const key in currentRecord) {
      if (currentRecord.hasOwnProperty(key) && key !== 'changes') {
        if (currentRecord[key] !== previousRecord[key]) {
          currentRecord.changes[key] = true;
        }
      }
    }
  }
  console.log(data);
  
  return data;
}

}
