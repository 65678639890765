﻿import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';


@Injectable({
    providedIn: 'root'
})
export class UserAddressService extends BaseService {

    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/UserAddress`);
    }

    GetsByUserId(UserId: number) {
        const queryString = `${this.serviceUri}?UserId=${UserId}`;
        return this.defaultGet(queryString);
    }

    GetsAddressByUserId(key: string, id:number, offset: number, limit: number, sortField: string, isAsc: boolean) {
        const queryString = `${this.serviceUri}/GetsAddressByUserId?key=${key}&userId=${id}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }

    SetDefaultAddress(id: number, userId: number) {
        const queryString = `${this.serviceUri}/SetDefaultAddress?id=${id}&userId=${userId}`;
        return this.defaultPost(queryString, {});
    }

    GetByUserIdEditOrder(userId: number) {
        const queryString = `${this.serviceUri}/GetByUserIdEditOrder?userId=${userId}`;
        return this.defaultGet(queryString);
    }

    GetDefault(userId: number) {
        const queryString = `${this.serviceUri}/GetDefault?userId=${userId}`;
        return this.defaultGet(queryString);
    }

    GetIsSyn() {
        const queryString = `${this.serviceUri}/GetIsSyn?limit=10`;
        return this.defaultGet(queryString);
    }

    AdminDeleteAddress(id: number) {
        const queryString = `${this.serviceUri}/AdminDeleteAdress?id=${id}`;
        return this.defaultDelete(queryString);
    }

    GetDetailAddress(id: number) {
        const queryString = `${this.serviceUri}/GetDetailAddress/${id}`;
        return this.defaultGet(queryString);
    }

    AdminSave(form: any) {
        const queryString = `${this.serviceUri}/AdminSave`;
        return this.defaultPost(queryString, form);
    }

}

