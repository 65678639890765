import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ComponentBase } from '../../../classes/base/component-base';
import { UserService } from '../../../services/user.service';
export class FileViewerComponent extends ComponentBase {
    constructor(_UserService) {
        super();
        this._UserService = _UserService;
        this.isPopupVisible = false;
        this.popupPosition = { x: 0, y: 0 };
        this.showFileDialog = false;
        this.selectedIndex = 0;
        this.idPharmacy = -1;
        this.listFile = [];
        this.file = {
            filenName: '',
            // path: '',
            type: 1,
            url: ''
        };
        this.commands = [
            { label: "Gán cho giấy tờ DKDN", action: 1 },
            { label: "Gán cho giấy chứng nhận DDKKDD", action: 2 },
            { label: "Gán cho GDP/GPP/GSP", action: 3 }
        ];
    }
    ngOnInit() {
    }
    // Sử dụng với file list idFile
    showPopup(idFile, data = []) {
        this.listFile = [];
        this.file.type = 1;
        this.file.url = this.getImageOriginal(idFile);
        if (data.length > 0) {
            data.forEach(item => {
                this.listFile.push({ id: item, url: this.getImageOriginal(item), type: 1 });
            });
            this.file = this.listFile.find(s => s.id == idFile); // Gán lại file trong list để hàm next tìm được
        }
        // const fileType = this.getFileType(this.FileName);
        // const documentType = this.getDocumentType(fileType);
        // if (documentType == 'image') {
        // this.isImage = true;
        // }
        // else {
        //     this.isImage = false;
        //     this.initDocumentViewer(fileType, documentType);
        // }
        this.showFileDialog = true;
    }
    // Sử dụng với List Object File
    showPopupObject(file, data = []) {
        if (data.length > 0) {
            this.listFile = data.filter(x => x.type == 1); // Chỉ view file image và video
        }
        this.file = file;
        if (this.file.type == 3)
            this.file.url = `${environment.apiDomain.fileEndpoint}${this.file.path}`;
        else
            this.file.url = this.urlImageOriginal(this.file.path);
        this.showFileDialog = true;
        this.idPharmacy = file.idPharmacy;
    }
    getCurrent(item, index) {
        const currentIndex = this.listFile.indexOf(item);
        this.selectedIndex = currentIndex;
        this.file = this.listFile[currentIndex];
        this.file.url = item.path ? this.urlImageOriginal(this.file.path) : this.file.url;
    }
    onNext() {
        if (this.file == null || this.file.path == "" || this.listFile == null || this.listFile.length <= 0)
            return;
        const currentIndex = this.listFile.indexOf(this.file);
        const nextIndex = (currentIndex + 1) % this.listFile.length;
        this.file = this.listFile[nextIndex];
        this.file.url = this.file.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.selectedIndex = nextIndex;
    }
    onPrev() {
        if (this.file == null || this.file.path == "" || this.listFile == null || this.listFile.length <= 0)
            return;
        const currentIndex = this.listFile.indexOf(this.file);
        let prevIndex = 0;
        if (currentIndex <= 0)
            prevIndex = this.listFile.length - 1;
        else
            prevIndex = currentIndex - 1;
        // prevIndex = (currentIndex - 1) % this.listFile.length;
        this.file = this.listFile[prevIndex];
        this.file.url = this.file.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.selectedIndex = prevIndex;
    }
    onKeydownHandler(event) {
        // event.preventDefault();
        if (event.key === 'ArrowLeft') {
            //if (event.keyCode == 37) {
            this.onPrev();
        }
        if (event.key === 'ArrowRight') {
            this.onNext();
        }
    }
    // urlFile(file) {
    //     if (file.path == null || file.path == undefined)
    //         return `/assets/images/no_image.jpg`; 
    //     return `https://docs.google.com/viewerng/viewer?url=https://wmapi.friends.vn/file.api//2023/9/23/mickey-tai-lieu-api_20230923115008708.docx`;
    // }
    // command menu
    openPopup(event, item) {
        if (this.idPharmacy > 0) {
            event.preventDefault();
        }
        let posX = event.clientX;
        let posY = event.clientY;
        const popupWidth = 150;
        const popupHeight = 100;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        if (posX + popupWidth > viewportWidth) {
            posX = viewportWidth - popupWidth;
        }
        if (posY + popupHeight > viewportHeight) {
            posY = viewportHeight - popupHeight;
        }
        this.popupPosition = { x: posX, y: posY };
        this.isPopupVisible = true;
    }
    command_option(label, actionType, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isConfirmed = window.confirm(`Bạn có chắc chắn muốn thực hiện lệnh: ${label}?`);
            if (isConfirmed) {
                yield this._UserService.AssignCertificateType(this.idPharmacy, file.id, actionType)
                    .then(response => {
                    console.log('API thành công:', response);
                })
                    .catch(error => {
                    console.error('API lỗi:', error);
                });
                this.isPopupVisible = false;
            }
            console.log(this.idPharmacy);
            console.log(file.id);
        });
    }
    closePopup() {
        this.isPopupVisible = false;
    }
}
