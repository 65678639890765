<p-dialog #dialog [header]="file?.fileName" styleClass="vs-modal force-full-width file-viewer" [showHeader]="false" positionBottom="0" positionTop="0" [(visible)]="showFileDialog" [style]="{'bottom':'0'}" [contentStyle]="{'height': '100vh'}" [modal]="true" [maximizable]="true" appendTo="body">
    <div class="modal-body">
        <div class="fileinfo">{{file?.fileName}}</div>
        <a *ngIf="listFile?.length > 0" (click)="onPrev()" class="ui-state-default ui-lightbox-nav-left ui-corner-right" style="z-index: 1003;"><span class="ui-lightbox-nav-icon pi pi-chevron-left"></span></a>

        <div *ngIf="file.type == 3" class="box-content">
            <video controls="" autoplay="" name="media">
                <source [src]="file?.url" type="video/{{file.extention.replace('.','')}}">
            </video>
        </div>

        <div *ngIf="file.type == 1" class="box-content">
            <img [src]="file?.url" />

            <!-- <iframe *ngIf="file?.type != 1" width="100%" height="700px" [src]="urlFile(file) | safe" frameborder="0" allowfullscreen></iframe> -->
        </div>

        <a *ngIf="listFile?.length > 0" (click)="onNext()" class="ui-state-default ui-lightbox-nav-right ui-corner-left" style="z-index: 1003;"><span class="ui-lightbox-nav-icon pi pi-chevron-right"></span></a>

        <a (click)="showFileDialog=false" class="btnClose"><span class="pi pi-times"></span></a>
    </div>

    <div class="list_img" *ngIf="file.path">
        <div *ngFor="let item of listFile; let idx = index" class="img" [ngClass]="{'active': idx === selectedIndex}">
            <img *ngIf="item.type == 1" style="width: 60px;height: 100%;" [src]="urlImageOriginal(item.path)" (click)="getCurrent(item, idx)" (contextmenu)="openPopup($event, item)">
        </div>
    </div>

    <div *ngIf="isPopupVisible && idPharmacy > 0" class="popup-menu" [ngStyle]="{'top.px': popupPosition.y, 'left.px': popupPosition.x}">
        <div class="command-container">
            <div *ngFor="let command of commands" class="btn-command" (click)="command_option(command.label, command.action, file)">
                {{ command.label }}
            </div>
        </div>
    </div>

    <div class="list_img" *ngIf="!file.path">
        <div *ngFor="let item of listFile; let idx = index" class="img" [ngClass]="{'active': idx === selectedIndex}">
            <img *ngIf="item.type == 1" style="width: 60px;height: 100%;" [src]="item.url" (click)="getCurrent(item, idx)">
        </div>
    </div>

</p-dialog>